import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, FormControlLabel, Switch, TextField, Typography, styled } from '@mui/material';
import { postFile } from '../../static/api';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#268C8C' : '#67C4C5',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const BrandInput = ({ brandData, update }) => {
  const [logo, setLogo] = useState('');
  const [icon, setIcon] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [tertiaryColor, setTertiaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [headerFont, setHeaderFont] = useState('');
  const [bodyFont, setBodyFont] = useState('');
  const [baseMode, setBaseMode] = useState(true);
  const [returnUrl, setReturnUrl] = useState('');

  useEffect(() => {
    if (brandData && brandData.logo) {
      console.log(brandData);
      setLogo(brandData.logo);
      setIcon(brandData.icon);
      setReturnUrl(brandData.returnUrl);
      setPrimaryColor(brandData.theme.primary);
      setSecondaryColor(brandData.theme.secondary);
      setTertiaryColor(brandData.theme.tertiary);
      setHeaderFont(brandData.theme.fonts.header);
      setBackgroundColor(brandData.theme.background);
      setBodyFont(brandData.theme.fonts.body);
      setBaseMode(brandData.theme.base_mode === 'dark');
    }   
  }, [brandData]);

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await postFile(file);
        if (response) {
          setLogo(response);
        }
      } catch (error) {
        console.error('Error uploading file to S3:', error);
      }
    }
  };

  const handleIconChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await postFile(file);
        if (response) {
          setIcon(response);
        }
      } catch (error) {
        console.error('Error uploading file to S3:', error);
      }
    }
  };

  function switchChange(event) {
    setBaseMode(event.target.checked);
  }

  useEffect(() => {
    //if (logo !== '') {
      const updatedBrandData = {
        logo,
        icon,
        returnUrl,
        theme: {
          primary: primaryColor,
          secondary: secondaryColor,
          tertiary: tertiaryColor,
          background: backgroundColor,
          base_mode: baseMode ? 'dark' : 'light',
          fonts: {
            header: headerFont,
            body: bodyFont
          }
        }
      };
  
      update(updatedBrandData);
    //}
  }, [bodyFont, headerFont, logo, icon, returnUrl, backgroundColor, baseMode, primaryColor, secondaryColor, tertiaryColor])

  return (
    <Card elevation={2} sx={{my: 2,m: 0}}>
      <CardContent sx={{my: 2, p:0}}>

        <Typography color="text.primary" mb={1} variant={'h6'}>Branding</Typography>
        <Button variant="contained" component="label" sx={{margin: 2}}>
          Upload Logo
          <input
            hidden
            type="file"
            accept=".jpg,.png,.jpeg,.svg"
            onChange={handleLogoChange}
          />
        </Button>
        {logo !== '' && 
          <img src={logo} alt='' style={{
            maxWidth: '40%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}/>
        }
        
        <Button variant="contained" component="label" sx={{margin: 2}}>
          Upload Favicon
          <input
            hidden
            type="file"
            accept=".jpg,.png,.jpeg,.svg"
            onChange={handleIconChange}
          />
        </Button>
        {icon !== '' && 
          <img src={icon} alt='' style={{
            maxWidth: '20%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}/>
        }

        <TextField
          helperText="Return Url"
          value={returnUrl}
          onChange={(e) => setReturnUrl(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          helperText="Primary Color"
          value={primaryColor}
          onChange={(e) => setPrimaryColor(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          helperText="Secondary Color"
          value={secondaryColor}
          onChange={(e) => setSecondaryColor(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          helperText="Tertiary Color"
          value={tertiaryColor}
          onChange={(e) => setTertiaryColor(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          helperText="Background Color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          helperText="Header Font"
          value={headerFont}
          onChange={(e) => setHeaderFont(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          helperText="Body Font"
          value={bodyFont}
          onChange={(e) => setBodyFont(e.target.value)}
          fullWidth
          variant="standard"
        />

        <Typography>Base Theme</Typography>
        <FormControlLabel
          control={<MaterialUISwitch sx={{ m: 1 }} checked={baseMode} onChange={switchChange}/>}
          label=""
          sx={{mr: 0}}
        />
      </CardContent>
    </Card>
  );
};

export default BrandInput;
