/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Paper, Typography } from '@mui/material';

const CountdownSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
`;

const CountdownNumber = styled.span`
  font-size: 2em;
  color: black;
`;

const CountdownLabel = styled.span`
  font-size: 1em;
  color: black;
  text-transform: uppercase;
`;

const Countdown = ({ endDate, startDate }) => {
  const [type, setType] = useState('');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const currentUnixTime = moment().unix();
    let difference = 0;
    //const difference = +moment.unix(endDate) - +new Date();
    //console.log(currentUnixTime, startDate, endDate);
    if (currentUnixTime < startDate) {
      //console.log(startDate);
      difference = +moment.unix(startDate) - +new Date();
    } else {
      difference = +moment.unix(endDate) - +new Date();
    }

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    //console.log(timeLeft);
    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Paper sx={{  p: 4 }} elevation={2}>
      <Box display={'flex'} >
        {Object.keys(timeLeft).map((interval, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mx: 4,
              flex: 1
            }}
          >
            <Typography fontSize={30} color={'text.primary'}>
              {timeLeft[interval]}
            </Typography>
            <Typography
              fontSize={20}
              color={'text.primary'}
              textTransform={'uppercase'}
            >
              {interval.toUpperCase()}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box alignItems={'center'} justifyItems={'center'} display={'flex'} flex={1}>
        {moment().unix() < startDate ? 
          <Typography color={'text.primary'} textTransform={'uppercase'} textAlign={'center'} width={'100%'} letterSpacing={9} pt={3} m={0}>Time Until Start</Typography> :
          <Typography color={'text.primary'} textTransform={'uppercase'} textAlign={'center'} width={'100%'} letterSpacing={9} pt={3} m={0}>Time Remaining</Typography>
        }
      </Box>
      
    </Paper>
  );
};

export default Countdown;
