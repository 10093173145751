import React, { useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ErrorToast({errorMessage}) {
  const [openError, setOpenError] = React.useState(false);
  const [currentError, setCurrentError] = React.useState('');
  const handleCloseError = () => {
    setOpenError(false);
    setCurrentError('');
  };  
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, []);

  useEffect(() => {    
    if ( errorMessage != null && errorMessage !== '') {
      setCurrentError(errorMessage);
      setOpenError(true);
    } 
    setTimeout(() => {
      setOpenError(false);
      //setCurrentError('');
    }, 5000)
    
  }, [currentError, errorMessage])

  const errorAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return ( 
    <Snackbar
      sx={{maxWidth: '80vw', textOverflow: 'ellipsis', wordWrap: 'break-word', overflowX: 'hidden'}}
      open={openError}
      autoHideDuration={6000}
      onClose={handleCloseError}
      message={currentError}
      action={errorAction}
    />
  )
};

export default ErrorToast;
