
import {Box, Card, Container, IconButton, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import { getValue, makeId } from '../../static/utility';
import moment from 'moment';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AddressOutput from '../AddressOutput';
import { TypeSpecimen } from '@mui/icons-material';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Referrer', flex: 7,  
    renderCell: (params: GridRenderCellParams<String>) => (
      <AddressOutput address={params.value as string} variant={'body1'} /> 
    ) 
  },
  { field: 'total', headerName: 'Total (uint256)', flex: 3}
];
const NoRowsOverlay = () => (
  <Box>
    <Typography>No Referrers</Typography>
  </Box>
);

export default function TopReferrers({customResults}) {
  const [resetList, setResetList] = useState('');
  const [data, setData] = useState([]);
  useEffect(() => {
    //const ret = makeId(34);
    //console.log(customResults);
    setResetList('Main Content');
    if (customResults) {
      const _dataObject = customResults.reduce((finalVal, result, i) => {
        const currentVal = getValue(result);
        //console.log(currentVal);
        if (result.referrer) {
          if (Object.hasOwn(finalVal, result.referrer)) {
            return {...finalVal, [result.referrer]: {
              name: finalVal[result.referrer].name,
              total: finalVal[result.referrer].total + currentVal
            }}
          } 

          return {...finalVal, [result.referrer]: {
            name: result.referrer,
            total: currentVal
          }}
        }
        return finalVal;
      }, {});
      const _data = Object.keys(_dataObject).map(key => {return {..._dataObject[key], id: makeId(25)}}).sort((a,b) => {
        return b.total - a.total;
      }).slice(0,4);
      setData(_data);
      console.log(_data)
    }
    //console.log(customResults);
  }, [customResults])
  useEffect(() => {
    const ret = makeId(34);
    //console.log(ret);
    setResetList('Main Content')
  }, [])
  return (
    <Stack
      sx={{
        minWidth: "100%",
        display: "flex",
        justifyContent: "center",
        px:2
      }}
      spacing={0}
    >
      
      <Typography color={'text.primary'} textAlign={'center'}>{'Top Referrers'}</Typography>
      {
        <DataGrid 
          rows={data} 
          columns={columns} 
          sx={{border: 0, }} 
          autoHeight 
          {...data} 
          hideFooterPagination={true}
          />
      }
    </Stack>
    
  )
}