
import {Box, Button, Card, CircularProgress, Container, IconButton, Paper, Stack, TextField, Typography, useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import { makeId } from '../static/utility';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useNetwork,
  useSignMessage,
} from 'wagmi';
import { Refresh } from '@mui/icons-material';
import { Web3Button, useWeb3ModalTheme } from '@web3modal/react';
import React from 'react';
import { useIsLoggedIn } from '../hooks/useLoginState';
import { getLogout, getToken, postToken, putToken } from '../static/api';
import { useNavigate } from 'react-router-dom';
import ErrorToast from '../components/ErrorToast';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

export default function Settings() {
  const theme2 = useTheme();
  const navigate = useNavigate();
  const mdUp = useMediaQuery(() => theme2.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const { loggedIn, reload: validateLogin } = useIsLoggedIn();
  const { connector: activeConnector, isConnected, address } = useAccount();
  const { data, isError, isLoading, isSuccess, signMessageAsync } = useSignMessage()
  const { chain } = useNetwork();
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { theme, setTheme } = useWeb3ModalTheme();

  async function logout () {
    const result = await getLogout();
    navigate('/')
  }

  async function getTokenData() {
    try {
      const result = await getToken();
      //console.log(result);
      if (!result.data) {
      } else {
        //console.log(result.data);
        setToken(result.data);
      }
    } catch (err) {
      //console.log(err);
    }
    
  }
  
  async function createToken() {
    const result2 = await postToken();
    if (result2.data) { 
      //console.log(result2.data);
      setToken(result2.data)
    }
  }

  async function refreshToken() {
    const result2 = await putToken();
    if (result2.data) { 
      console.log(result2.data);
      setToken(result2.data)
    }
  }
  useEffect(() => {
      getTokenData();
    
  }, []);
  return (
    <Grid
      container
      sx={{
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
      spacing={0}
      alignItems="center"
      justifyItems="center"
    >
      
      <Typography color={'text.primary'} variant={'h3'}>Account Settings</Typography>
      <Paper elevation={0} sx={{ maxWidth: 1200 , minWidth: mdUp ? '40rem' : '20rem'}} className="transparent-background">
        { !submissionLoading && 
          <Box sx={{alignItems: 'flex-end'}} p={2}>
            <Box maxWidth="max" sx={{ flexDirection: 'row', p: 2}}> 
              <Box sx={{ justifyContent: 'center', textAlign: 'center'}}>
                {token === '' && 
                  <Button sx={{mx: 'auto'}} onClick={createToken} variant='contained' color={'secondary'}>Generate Token</Button>
                }
                {token !== '' && 
                  <TextField
                    label={''}
                    value={token}
                    helperText={'API Token'}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      endAdornment: 
                        <IconButton onClick={refreshToken} title='Refresh Token'>
                          <Refresh />
                        </IconButton>
                      ,
                    }}
                  />
                }
              </Box>
            </Box>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Web3Button />
              <Button variant='contained' onClick={logout}>Logout</Button>
            </Stack>
            {}
          </Box>
          
        }
        {submissionLoading && 
          <Box display={'flex'}>
            <Box sx={{display: 'inline-flex', mx: 'auto' }} py={'25vh'}>
              <CircularProgress />
            </Box>
            
          </Box>
        }
      </Paper>
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
    </Grid>
    
  )
}