import {env} from '../static/constants';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider
} from "@web3modal/ethereum";
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { ethers } from 'ethers';
import { avalanche, bsc, mainnet, polygon, optimism, arbitrum, localhost, hardhat, polygonMumbai } from 'wagmi/chains';

const chains = [polygonMumbai, polygon, arbitrum];
const projectId = env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
// Wagmi client
const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
    alchemyProvider({ apiKey: env.REACT_APP_ALCHEMY_MUMBAI }),     
    alchemyProvider({ apiKey: env.REACT_APP_ALCHEMY_ARBITRUM }), 
    publicProvider()
])

const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient
})

export {
  chains, 
  wagmiClient
}