import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Box, useTheme } from '@mui/material';

const SidebarLayout = ({store, setTheme}) => {
  const theme = useTheme();
  return (
    <>
      <Sidebar store={store} setTheme={setTheme} />
      <Box sx={{
        display: 'flex',
        height: '100%',
        //minHeight: 'calc(100vh)',
        //overflowX: 'hidden',
        marginLeft: {md: '250px'},
        backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#282c34',
      }}>
        <Outlet />
      </Box>
</>
  )
};

export default SidebarLayout;