import { JsonFragment } from '@ethersproject/abi';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

function ImportAbi({onChange, existingAbi, existingCommand, type}) {
  const [jsonData, setJsonData] = useState({});
  const [_interface, setInterface] = useState({} as any);
  const [options, setOptions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState('');
  

  const handleFileUpload = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      const parsedJsonData = JSON.parse(e.target.result as string);
      setJsonData(parsedJsonData);
    };
  };

  const handleFunctionSelect = (event) => {
    setSelectedFunction(event.target.value);
    onChange(event.target.value, JSON.stringify(jsonData['abi']));
  }

  const handleClearClick = () => {
    setSelectedFunction('');
    setOptions([]);
    setInterface({});
    setJsonData({});
    onChange('', '');
  }

  useEffect(() => {
    if (jsonData['abi']) {
      const abiInt = new ethers.utils.Interface(jsonData['abi']);
      //console.log(abiInt);
      if (type === 'Event') {
        setOptions(Object.keys(abiInt.events));
      } else {
        setOptions(Object.keys(abiInt.functions));
      }      
      //console.log(abiInt);
    } else {
      setOptions([]);
    }
  }, [jsonData])

  useEffect(() => {
    if (existingAbi) {      
      //console.log('has existing abi', existingAbi)
      setJsonData({abi: existingAbi});
    }
  }, [existingAbi])

  useEffect(() => {
    if (existingCommand) {      
      setSelectedFunction(existingCommand);
    }
  }, [existingCommand])

  return (
    <>
      {!jsonData['abi'] && 
        <Button variant="contained" component="label" sx={{marginY: 2}}>
          Upload Contract Abi
          <input hidden accept="application/json" type="file" onChange={handleFileUpload} />
        </Button>
      }
      
      {options.length > 0 && 
        <FormControl fullWidth>
          <Select
            labelId="FunctionSelect"
            id="FunctionSelectInput"
            value={selectedFunction}
            label={type + " Select"}
            onChange={handleFunctionSelect}
            variant={'standard'}
            endAdornment={<IconButton sx={{display: selectedFunction ? "": "none"}} onClick={handleClearClick}><ClearIcon/></IconButton>}
            sx={{"& .MuiSelect-iconStandard": {display: selectedFunction ? 'none': ''}}}
          >
            {options.map((opt, i) => {
              return (
                <MenuItem value={opt} key={i}>{opt}</MenuItem>
              )
            })}
          </Select>
          <Typography variant={'caption'} sx={{color: '#65748B'}}>{type + " Select"}</Typography>
        </FormControl>
      }
    </>
  );
}

export default ImportAbi;
