import {Box, Stack,Typography, useMediaQuery, useTheme} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import LandingImage from '../assets/images/landing-hero.png';
import SalesforceImage from '../assets/images/salesforce.png';
import HubspotImage from '../assets/images/hubspot.png';
import ZohoImage from '../assets/images/zoho.png';
import GoogleSheetsImage from '../assets/images/google-sheets.png';
import SquareImage from '../assets/images/square.png';
import { useAccount } from 'wagmi';
import { useEffect } from 'react';
import NewCampaign from '../components/CampaignButton';
import YoutubeVideo from '../components/YoutubeVideo';
import Footer from '../components/Footer';

const logos = [
  SalesforceImage,
  HubspotImage,
  ZohoImage,
  GoogleSheetsImage,
  SquareImage,
];

export default function LandingPage() {
  const theme = useTheme();
  const { isConnected } = useAccount();
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });

  useEffect(() => {
    if (isConnected) {
      //navigate('/dashboard')
    }
  }, [isConnected])
  return (
    <Box
      sx={{
        minWidth: "100%",
        
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundImage: "linear-gradient(270deg, #0E0D46 0%, #281F70 100%)",
        backgroundPositionX: 'center' 
      }}
    >
      <Grid 
        container
        spacing={0}
        alignItems="start"
        justifyItems="center"
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(${LandingImage})`,
          backgroundPositionX: 'right',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          marginBottom: 3
        }}
      >
        <Grid xs={12} mt={mdUp ? '25vh' : 'auto'} p={2}>
          <Stack direction={'column'} width={mdUp ? '45vw' : '100%'} >
            <Typography fontFamily={'BebasNeue'} color={'white'} fontSize={mdUp ? 70 : 40} textAlign={'left'} fontWeight={10} marginBottom={4}>
              Smart Referral Campaign
            </Typography>
            <Typography fontFamily={'Lato'} color={'white'} fontSize={mdUp ? 25 : 15} textAlign={'left'} fontWeight={4} mb={1}>
              Web3 native marketing automation and workflow integration tool
            </Typography>
            <Typography fontFamily={'Lato'} color={'white'} fontSize={mdUp ? 25 : 15} textAlign={'left'} fontWeight={4} mb={1}>
              We use Smart Contracts to track on-chain and off-chain user behavior and write workflow automation to automatically track, identify, and reward your users.
            </Typography>
            <Typography fontFamily={'Lato'} color={'white'} fontSize={mdUp ? 25 : 15} textAlign={'left'} fontWeight={'bold'} mb={1}>
              Connect your wallet and create a Campaign
            </Typography>
            {isConnected && 
              <Box sx={{mt: 4}}>
                <NewCampaign campaignId={null}/>
              </Box>          
            }
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{
        minHeight: "20vh",
        backgroundColor: 'rgba(0,0,0,.8)',
        padding: 2,
        py: 6
      }}>
        <Stack direction={'column'} py={8} px={4}>
          <Typography fontFamily={'Lato'} color={'white'} fontSize={mdUp ? 25 : 20} textAlign={'left'} fontWeight={4} mb={1}>
            What systems do you currently use and what workflows do you want Smart Campaigns to integrate with? 
          </Typography>
          <Typography fontFamily={'Lato'} color={'white'} fontSize={mdUp ? 25 : 20} textAlign={'left'} fontWeight={4} mb={1}>
            Contact us to use our existing integrations or tell us what other systems you want us to integrate with. We have you covered.
          </Typography>
        </Stack>

        <Stack py={8} direction={mdUp ? 'row' : 'column'} justifyContent={'space-around'} alignItems={'center'} spacing={3} >
          {
            logos.map((logo, i) => {
              return (
                <Box 
                  sx={{
                    backgroundImage: `url(${logo})`,
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'center',
                    backgroundSize: '175px',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: 'white',
                    border: '1px solid #777',
                    width: 200,
                    height: 200,
                    paddingX: 0,
                    borderRadius: 6
                  }}
                  key={i}
                ></Box>
              )
            })
          }
        </Stack>
      </Box>
      <Box sx={{
        minHeight: "20vh",
        backgroundColor: 'transparent',
        padding: 2,
        py: 15
      }}>
        <YoutubeVideo embedId={'0KUa_LldXC0'} />
      </Box>
      <Footer />
    </Box>
  )
}