// @ts-nocheckd
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  Stack,
  Typography,
  Card
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { env, useEnvVariable } from '../static/constants';
import { asExecuteArg, countParameters } from '../static/utility';

import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useChainId
} from 'wagmi';
import { getAddress, zeroAddress, isAddress, etherUnits, parseEther, toHex, decodeEventLog } from 'viem'
import { useAbi } from '../assets/abis/abi';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import ImportAbi from '../components/inputs/ImportAbi';
//import {mintChannel} from '../static/blockchain';
import { postCampaignOffChainV1, postCampaignOffChain, getLoginStatus, getCampaign, putCampaignOffChain, putCampaignOffChainV1 } from '../static/api';
import { useIsLoggedIn } from '../hooks/useLoginState';

// Custom Components
import ErrorToast from '../components/ErrorToast';
import { CircularProgress, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import ValidateParameters from '../components/inputs/ValidateParameters';
import DateInput from '../components/inputs/DateInput';
import BigNumberInput from '../components/BigNumberInput';
import GoalInput from '../components/inputs/GoalManagement';
import moment from 'moment';
import Version2 from '../components/CampaignEdit/Version2'
import Version3Form from '../components/CampaignEdit/Version3';
import Version2Form from '../components/CampaignEdit/Version2';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';


//mintChannel
//const svgString = basicSvg.svgPartOne + basicSvg.svgPartTwo + basicSvg.svgPartThree
const steps = ['Create Campaign', 'Set Rights'];
const amount = parseEther('0.000000001');

function CampaignEdit() {
  let navigate = useNavigate();
  const theme = useTheme();
  const { campaignId } = useParams();
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const [campaignVersion, setCampaignVersion] = useState(0);
  const {loggedIn, reload: validateLogin} = useIsLoggedIn();
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { address } = useAccount();
  const chainId = useChainId();
  const [factoryAddress, billingAddress] = useEnvVariable();
  const { smartCampaignBilling } = useAbi();

  const [allArgs, setAllArgs] = useState([]);
  const [campaignArgs, setCampaignArgs] = useState('');
  const [hasBeenMinted, setHasBeenMinted] = useState(false);
  const [campaign, setCampaign] = useState({});
  const [campaignGoals, setCampaignGoals] = useState([]);
  const addGoal = () => {
    setCampaignGoals([...campaignGoals, {
      label: '',
      type: '',
      customerKey: 0,
      minValue: BigInt(0),
      valueKey: 0,
      eventSignature: '',
      eventContractAddress: '',
      eventChainId: '',
      abi: '',
    }])
  }
  const updateGoal = (goal, index) => {
    const newGoals = campaignGoals.map((_goal, _index) => {
      if (_index === index) {
        return goal;
      }
      return _goal;
    });
    setCampaignGoals(newGoals);
  }
  

  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(campaignId, billingAddress);
      console.log('getCampaignResult',camp);
      if (camp && camp.version === 2) {
        setCampaignVersion(2);
        setCampaign(camp);
      } else if (camp) {
        setCampaignVersion(3);
        setCampaign(camp);
      }
      
    }
    if ( campaignId && campaignId !== '' && campaignId !== null && billingAddress !== null && billingAddress !== '' && loggedIn ) {
      setHasBeenMinted(true);
      runAsync();  
    }
  }, [campaignId, billingAddress, loggedIn])

  useEffect(() => {
    validateLogin();
  }, [])
  /**/


  function VersionSelection () {
    return (
      <Box sx={{ alignItems: 'center', justifyItems: 'center' }} p={2}>
        <Stack direction={'row'} spacing={2} justifyContent={'space-around'} py={2}>
          <Button variant={'outlined'} color={'secondary'} onClick={() => {setCampaignVersion(2)}}>Version 2</Button>
          <Button variant={'outlined'} color={'secondary'} onClick={() => {setCampaignVersion(3)}}>Version 3</Button>
        </Stack>
      </Box>
    )
  }

  function LoadingSection () {
    return (
      <Box display={'flex'}>
        <Box sx={{ display: 'inline-flex', mx: 'auto' }} py={'25vh'}>
          <CircularProgress />
        </Box>

      </Box>
    )
  }

  return (
      <Box sx={{display: 'flex', width: '100%'}}>
        <Card elevation={2} sx={{m: mdUp ? 5 : 0, my: 5, width: '100%'}} >
          {
            /*!submissionLoading && campaignVersion === 2 && 
            <Version2Form campaign={campaign} closeNewCampaignModal={closeNewCampaignModal}/>*/
          }
          {!submissionLoading && 
            <Version3Form campaign={campaign} />
          }
          {/*
            !submissionLoading && campaignVersion === 0 && 
            <VersionSelection />*/
          }
          {submissionLoading &&
            <LoadingSection />
          }
        </Card>
        <ErrorToast errorMessage={errorMessage}></ErrorToast>
      </Box>
  );
}

export default CampaignEdit;


