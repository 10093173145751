import axios from 'axios';
import { env } from './constants';
import { makeId } from './utility';
//const isProd = !env.REACT_APP_IS_NOT_PROD;
const isProd = process.env.NODE_ENV === 'production';
const apiVersion = 'v2/';

export const formatString = function(format) {
  var args = Array.prototype.slice.call(arguments, 1);
  return format.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] != 'undefined'
      ? args[number] 
      : match
    ;
  });
}

export const getCampaign = async (campaignId, factoryAddress) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'campaigns/'+factoryAddress+'/'+campaignId;
  //console.log(reqUrl);
  let metadataRequest = await fetch(reqUrl, {credentials: 'include'});
  const response = await metadataRequest.json();  
  return response;
}

export const getCampaignResults = async (campaignId, contractAddress, chainId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'transactions/'+contractAddress+ '/'+ chainId + '/'+campaignId;
  console.log(reqUrl);
  let metadataRequest = await fetch(reqUrl, {credentials: 'include'});
  const response = await metadataRequest.json();  
  return response;
}

export const getCustomCampaignResults = async (id) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'events/'+id;
  console.log(reqUrl);
  let metadataRequest = await fetch(reqUrl, {credentials: 'include'});
  const response = await metadataRequest.json();  
  return response;
}

export const postCampaign = async (txHash, abi, factoryAddress) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ 'v1/' +'campaigns/'+factoryAddress+'/'+ txHash;
  const metadataRequest = await axios.post(reqUrl, {
    abi
  }, { withCredentials: true });
  return metadataRequest;
}

export const postCampaignOffChain = async (campaign, factoryAddress, txHash) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'campaigns/'+factoryAddress+'/'+txHash+'/3';
  const metadataRequest = await axios.post(reqUrl, {
    campaign
  }, { withCredentials: true });
  return metadataRequest;
}

export const postCampaignOffChainV1 = async (campaign, factoryAddress, campaignId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'campaigns/'+factoryAddress+'/'+campaignId+'/2';
  const metadataRequest = await axios.post(reqUrl, {
    campaign
  }, { withCredentials: true });
  return metadataRequest;
}

export const putCampaignOffChainV1 = async (campaign, factoryAddress, campaignId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'campaigns/'+factoryAddress+'/'+campaignId+'/2';
  const metadataRequest = await axios.put(reqUrl, {
    campaign
  }, { withCredentials: true });
  return metadataRequest;
}

export const putCampaignOffChain = async (campaign, factoryAddress, campaignId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'campaigns/'+factoryAddress+'/'+campaignId+'/3';
  const metadataRequest = await axios.put(reqUrl, {
    campaign
  }, { withCredentials: true });
  return metadataRequest;
}

export const getCampaignOffChain = async (txHash, abi, factoryAddress) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'campaigns/'+factoryAddress+'/'+ txHash;
  const metadataRequest = await axios.post(reqUrl, {
    abi
  }, { withCredentials: true });
  return metadataRequest;
}

export const getLoginNoonce = async () => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/nonce';
  const metadataRequest = await axios.get(reqUrl, { withCredentials: true });
  return metadataRequest;
}

export const getLoginStatus = async () => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/ping';
  const metadataRequest = await axios.get(reqUrl, {withCredentials: true});
  return metadataRequest;
}

export const getLogout = async () => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/logout';
  const metadataRequest = await axios.get(reqUrl, {withCredentials: true});
  return metadataRequest;
}

export const postLoginVerification = async (message, signature) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/verify';
  const metadataRequest = await axios.post(reqUrl, {
    message,
    signature
  }, {withCredentials: true});
  return metadataRequest;
}

export const getToken = async () => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/token';
  const metadataRequest = await axios.get(reqUrl, {withCredentials: true});
  return metadataRequest;
}

export const postToken = async () => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/token';
  const metadataRequest = await axios.post(reqUrl, {}, {withCredentials: true});
  return metadataRequest;
}

export const putToken = async () => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'auth/token';
  const metadataRequest = await axios.put(reqUrl, {}, {withCredentials: true});
  return metadataRequest;
}

export const getAddressHistory = async (address, chainId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'wallets/'+address+'/'+chainId;
  const metadataRequest = await axios.get(reqUrl, {withCredentials: true});
  return metadataRequest;
}

export const getLeaderboardData = async (address, campaignId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'leaderboard/'+campaignId+'/'+address;
  const metadataRequest = await axios.get(reqUrl, {withCredentials: true});
  return metadataRequest.data;
}

export const postReferralEvent = async (data, factoryAddress, campaignId) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'transactions/internal/create/'+factoryAddress+'/'+campaignId;
  const metadataRequest = await axios.post(reqUrl, data);
  return metadataRequest;
}

export const hasReferralEvent = async (goalId, walletAddress) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'events/'+goalId+'/'+walletAddress;
  const metadataRequest = await axios.get(reqUrl);
  return metadataRequest;
}

export const postFile = async (file) => {
  const reqUrl = (isProd ? env.REACT_APP_API_URL : env.REACT_APP_API_URL_LOCAL)+ apiVersion +'s3/';
  const form = new FormData();
  console.log('postFile',file);
  form.append('image', file);

  const resp = await axios.post(reqUrl, form);
  //const metadataRequest = await axios.post(reqUrl, file);
  return resp.data;
}
