import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import BigNumberInput from '../BigNumberInput';
import { env } from '../../static/constants';

// Custom Components
import ErrorToast from '../ErrorToast';
import SignatureParser from './SignatureParser';
import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import { ethers } from 'ethers';
import ImportAbi from './ImportAbi';
import { isNotBlank } from '../../static/utility';
import AbiSelector from './AbiSelector';
import FilterBuilder from './FilterBuilder';

function GoalInput({goal, updateGoal, index}) {
  const [errorMessage, setErrorMessage] = useState('');

  const [goalInput, setGoalInput] = useState({
    label: '',
    type: '',
    customerKey: 0,
    minValue: '',
    valueKey: 0,
    eventSignature: '',
    eventContractAddress: '',
    eventChainId: '',
    abi: '',
    ctaButton: '',
    pointAmount: 0,
    referrerCommissionPercentage: 0
  });
  const [id, setId] = useState('');
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [customerKey, setCustomerKey] = useState(0);
  const [minValue, setMinValue] = useState('');
  const [valueKey, setValueKey] = useState(0);
  const [referrerCommissionPercentage, setReferrerCommissionPercentage] = useState(0);
  const [eventSignature, setEventSignature] = useState('');
  const [eventContractAddress, setEventContractAddress] = useState('');
  const [eventChainId, setEventChainId] = useState(0);
  const [functionSignature, setFunctionSignature] = useState('');
  const [functionContractAddress, setFunctionContractAddress] = useState('');
  const [functionChainId, setFunctionChainId] = useState(0);
  const [functionDefaultValues, setFunctionDefaultValues] = useState([]);
  const [ctaButton, setCTAButton] = useState('');
  const [bannerText, setBannerText] = useState('');
  const [hasValidation, setHasValidation] = useState(false);
  const [validationMethod, setValidationMethod] = useState('');
  const [validationDefaultValues, setValidationDefaultValues] = useState([]);
  const [existingFilter, setExistingFilter] = useState({function: '', filters: []});
  const [filter, setFilter] = useState({function: '', filters: []});
  const [abi, setAbi] = useState('');
  const handleAbiUpdate = (command, _abi) => {
    if (type === 'Referral') {
      setFunctionSignature(command);
    } else if (type === 'Event') {
      setEventSignature(command);
    }
    
    setAbi(_abi);
    //handleGoalChange();
  };
  const handleValidationUpdate = (_command) => {
    setValidationMethod(_command);
  }


  const [pointAmount, setPointAmount] = useState(0);

  function handleGoalChange () {
    //clearTimeout(submitTimer);
    //submitTimer = setTimeout(() => {
      console.log('handleGoalChange', filter);
      updateGoal({
        label,
        type,
        customerKey,
        minValue,
        valueKey,
        eventSignature,
        eventContractAddress,
        eventChainId,
        functionSignature,
        functionContractAddress,
        functionChainId,
        functionDefaultValues,
        abi,
        ctaButton,
        bannerText,
        pointAmount,
        referrerCommissionPercentage,
        validationMethod,
        hasValidation,
        validationDefaultValues,
        filter,
        _id: id
      }, index);
    //}, 1000)    
  };  

  useEffect(() => {
    //console.log(label, type, eventSignature, eventContractAddress, eventChainId, isNotBlank(abi))
    //console.log(isNotBlank(label));
    if (
      isNotBlank(label) && 
      isNotBlank(type) && 
      (
        (
          type === 'Event' && 
          isNotBlank(abi) && 
          isNotBlank(eventSignature) && 
          isNotBlank(eventContractAddress) && 
          eventChainId !== 0 
        ) ||
        type === 'API' ||
        type === 'Referral'
      )
    ) {
      console.log('handleGoalChange');
      handleGoalChange();
    }
  }, [label, type, customerKey, filter, validationMethod, validationDefaultValues, hasValidation, bannerText, ctaButton, minValue, valueKey, eventSignature, eventContractAddress, eventChainId, abi, pointAmount, functionSignature, functionContractAddress, functionChainId, abi, functionDefaultValues])

  useEffect(() => {
   if (goal) {
    setId(goal._id || '')
    setLabel(goal.label || '');
    setType(goal.type || '');
    setCustomerKey(goal.customerKey || 0);
    setMinValue(goal.minValue || '');
    setValueKey(goal.valueKey || 0);
    setEventSignature(goal.eventSignature || '');
    setEventContractAddress(goal.eventContractAddress || '');
    setEventChainId(goal.eventChainId || 0);
    setFunctionSignature(goal.functionSignature || '');
    setFunctionContractAddress(goal.functionContractAddress || '');
    setReferrerCommissionPercentage(goal.referrerCommissionPercentage ||0);
    setCTAButton(goal.ctaButton || '');
    setBannerText(goal.bannerText || '');
    setFunctionChainId(goal.functionChainId || 0);
    setAbi(goal.abi || '');
    setPointAmount(goal.pointAmount || 0)
    setFunctionDefaultValues(goal.functionDefaultValues || []);
    setValidationMethod(goal.validationMethod || '');
    setHasValidation(goal.hasValidation || false)
    setValidationDefaultValues(goal.validationDefaultValues || []);
    setFilter(goal.filter || {});
   }
  }, []);

  return (
    <Paper elevation={1} sx={{p: 3}} square>
      <TextField 
        helperText={'Label'}
        variant="standard"
        fullWidth
        value={label}
        onChange={(event) => {
          setLabel(event.target.value);
          //handleGoalChange();
        }}
        sx={{display: 'block', mx: "auto"}}
      />
      <Autocomplete
        disablePortal
        options={[
          '',
          'API',
          'Event',
          'Referral'
        ]}
        value={type}
        onChange={(event: any, newValue: string | null) => {
          setType(newValue);
          //handleGoalChange();
        }}
        renderInput={(params) => <TextField {...params} helperText="Type" variant="standard" fullWidth />}
      />

      {type === 'API' && 
        <></>
      }

      {type === 'Event' && 
        <>
          <TextField 
            helperText={'Event Contract'}
            variant="standard"
            fullWidth
            value={eventContractAddress}
            onChange={(event) => {
              setEventContractAddress(event.target.value);
              //handleGoalChange();
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          {
            <ImportAbi onChange={handleAbiUpdate} existingCommand={eventSignature} existingAbi={abi ? JSON.parse(abi) : false} type={'Event'}/>
          }

          <FormGroup>
            <FormControlLabel control={
              <Checkbox checked={hasValidation}
              onChange={(event) => {setHasValidation(event.target.checked)}}
              inputProps={{ 'aria-label': 'controlled' }} />
            } label="Validation Function?" />
          </FormGroup>

          {abi && hasValidation && 
            <AbiSelector onChange={handleValidationUpdate} existingCommand={validationMethod} existingAbi={abi ? JSON.parse(abi) : false} type={'Function'}/>
          }

          {validationMethod !== '' && abi && hasValidation && 
            <>
              <SignatureParser 
                signature={validationMethod} 
                currentValues={validationDefaultValues} 
                updateValues={setValidationDefaultValues} 
                type={'Enter default values for the event validation call.'}
              />
              <FilterBuilder existingFilters={filter} eventSignature={eventSignature} abi={abi} signature={validationMethod} update={setFilter} />
            </>
          }

          
          <TextField 
            helperText={'Event ChainId'}
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={eventChainId}
            onChange={(event) => {
              if(event.target.value) {
                setEventChainId(parseInt(event.target.value));
                //handleGoalChange();
              } else {
                setEventChainId(0);
              }
              
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          <TextField 
            helperText={'Referrer Commission'}
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={referrerCommissionPercentage}
            onChange={(event) => {
              if(event.target.value) {
                setReferrerCommissionPercentage(parseInt(event.target.value));
              } else {
                setReferrerCommissionPercentage(0);
              }
              
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          <TextField 
            helperText={'Customer Key'}
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={customerKey}
            onChange={(event) => {
              if(event.target.value) {
                setCustomerKey(parseInt(event.target.value));
                //handleGoalChange();
              } else {
                setCustomerKey(0);
              }
              
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          <TextField 
            helperText={'Value Key'}
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={valueKey}
            onChange={(event) => {
              if(event.target.value) {
                setValueKey(parseInt(event.target.value));
                //handleGoalChange();
              } else {
                setValueKey(0);
              }
              
            }}
            sx={{display: 'block', mx: "auto"}}
          />
        </>
      }

      {type === 'Referral' && 
        <>
          <TextField 
            helperText={'Contract Address'}
            variant="standard"
            fullWidth
            value={functionContractAddress}
            onChange={(event) => {
              setFunctionContractAddress(event.target.value);
              //handleGoalChange();
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          {
            <ImportAbi onChange={handleAbiUpdate} existingCommand={functionSignature} existingAbi={abi ? JSON.parse(abi) : false} type={'Function'}/>
          }

          {functionSignature !== '' && 
            <SignatureParser 
              signature={functionSignature} 
              currentValues={functionDefaultValues} 
              updateValues={setFunctionDefaultValues} 
              type={'Enter default values for the referral page.'}
            />
          }
          <TextField 
            helperText={'Contract ChainId'}
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={functionChainId}
            onChange={(event) => {
              if(event.target.value) {
                setFunctionChainId(parseInt(event.target.value));
                //handleGoalChange();
              } else {
                setFunctionChainId(0);
              }
              
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          <TextField 
            helperText={'Customer Key'}
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={customerKey}
            onChange={(event) => {
              if(event.target.value) {
                setCustomerKey(parseInt(event.target.value));
                //handleGoalChange();
              } else {
                setCustomerKey(0);
              }
              
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          <TextField 
            helperText={'CTA Button Text'}
            variant="standard"
            fullWidth
            value={ctaButton}
            onChange={(event) => {
              setCTAButton(event.target.value);
              //handleGoalChange();
            }}
            sx={{display: 'block', mx: "auto"}}
          />

          <TextField 
            helperText={'Banner Text'}
            variant="standard"
            fullWidth
            value={bannerText}
            onChange={(event) => {
              setBannerText(event.target.value);
              //handleGoalChange();
            }}
            sx={{display: 'block', mx: "auto"}}
          />
        </>
      }


      {
        /*<BigNumberInput label={goal} callback={handleCallbackInput} existingValue={0} />*/
        <TextField
          type="number"
          helperText={'Point Amount'}
          variant="standard"
          fullWidth
          value={pointAmount || ''}
          onChange={(e) => setPointAmount(Number(e.target.value))}
        />
      }
      
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </Paper>
  );
}

export default GoalInput;


