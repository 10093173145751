import { useEffect, useState } from 'react';
import { getLoginStatus } from '../static/api';

export const useIsLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  async function validateLogin() {
    try {
      await getLoginStatus();
      //console.log('login status', status);
      setLoggedIn(true);
    } catch (err) {
      //console.log(err);
      setLoggedIn(false);
    }
  }
  const reload = () => {
    validateLogin();
  }
  useEffect(() => {
    validateLogin();
  }, [])
  return { loggedIn, reload };
};