
import {Box, Button, Card, Container, Icon, IconButton, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import { makeId } from '../static/utility';
import { RefreshRounded } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { v4 as uuidv4 } from 'uuid';
import { getAddress, zeroAddress } from 'viem'
import {
  useAccount,
  useContractRead,
  useNetwork,
  usePublicClient
} from 'wagmi';
import { env, useEnvVariable } from '../static/constants';
import CampaignCard from '../components/CampaignCard';
import { useAbi } from '../assets/abis/abi';
import { BigNumber, ethers } from 'ethers';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useNavigate } from 'react-router-dom';
import { useIsLoggedIn } from '../hooks/useLoginState';
import { publicProvider } from 'wagmi/providers/public';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



export default function DashboardPage({store}) {
  const { address } = useAccount();
  const publicClient = usePublicClient();
  const {chain} = useNetwork();
  const [,billingAddress] = useEnvVariable();
  const { loggedIn } = useIsLoggedIn();
  const {smartCampaignBilling} = useAbi();
  const theme = useTheme();
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const navigate = useNavigate();
  const [resetList, setResetList] = useState('');
  const [tokenIndex, setTokenIndex] = useState(ethers.BigNumber.from(0));
  const [currentIndex, setCurrentIndex] = useState(ethers.BigNumber.from(0));
  const [reverseCampaignList, setReverseCampaignList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  

  useEffect(() => {
    //console.log(address,billingAddress,loggedIn);
    if (address && billingAddress && loggedIn) {
      console.log(chain);
      getCampaigns();
    }
  }, [address, billingAddress, loggedIn, chain])

  

  useEffect(() => {
    if (campaignList.length > 0) {
      const revList = campaignList.map((c) => c).reverse();
      setReverseCampaignList(revList);
    }
  }, [campaignList])

  const { data: tokenIndexData, refetch: tokenIndexFetch, error: tokenIndexError } = useContractRead({ 
    address: billingAddress ? getAddress(billingAddress) : zeroAddress, 
    abi: smartCampaignBilling?.abi, 
    functionName: 'getTokenIndex', 
    enabled: true 
  }) 

  const getCampaigns = async () => {
    console.log('getCampaigns');
    tokenIndexFetch();
    /*if (publicClient.readContract) {
      const res = await publicClient.readContract({
        address: billingAddress ? getAddress(billingAddress) : zeroAddress,
        abi: smartCampaignBilling?.abi,
        functionName: 'getTokenIndex',
        args : [],
      });
      if (res && loggedIn) {
        const _tokenIndex = ethers.BigNumber.from(res);
        setTokenIndex(_tokenIndex);
        const _tokenList = Array.apply(null, Array(_tokenIndex.toNumber())).map(function () {});
        
        const tokenList = _tokenList.map((val, i) => {
          return ethers.BigNumber.from(i);
        });
        console.log(tokenList);
        setCampaignList(tokenList);  
      }
    }*/
    
  }

  const goBack = () => {
    navigate('/')
  }

  useEffect(() => {
    if (tokenIndexData) {
      if (tokenIndexData && loggedIn) {
        const _tokenIndex = ethers.BigNumber.from(tokenIndexData);
        setTokenIndex(_tokenIndex);
        const _tokenList = Array.apply(null, Array(_tokenIndex.toNumber())).map(function () {});
        
        const tokenList = _tokenList.map((val, i) => {
          return ethers.BigNumber.from(i);
        });
        console.log(tokenList);
        setCampaignList(tokenList);  
      }
    }
  }, [tokenIndexData])

  return (
    <Box sx={{height: '100vh', display: 'block', width: '-webkit-fill-available'}}>
        <Grid2
          container
          spacing={2}
          sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#00000'}}
        >
          
          <Grid2 xs={2} md={1} sx={{pb:0}}>
            <IconButton sx={{m: 2, mb: 0}} aria-label="back" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          </Grid2>            
          <Grid2 xs={8} md={10} sx={{pb:0}}></Grid2>
          <Grid2 xs={2} md={1} sx={{pb:0}}>
            <IconButton sx={{m: 2, mb: 0}} aria-label="refresh" onClick={getCampaigns}>
              <RefreshRounded />
            </IconButton>
          </Grid2>
          {reverseCampaignList.length > 0 && 
            reverseCampaignList.map((row, i) => {
              const isLast = (reverseCampaignList.length-1) === i && !mdUp;
              return (
                <CampaignCard campaignId={row.toNumber()} isLast={isLast} key={uuidv4()}></CampaignCard>
              )
            }
          )}
        </Grid2>
       
    </Box>
  )
}
