// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  MobileStepper,
  Switch,
  Stack,
  Typography
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { env, useEnvVariable } from '../../static/constants';
import { asExecuteArg, countParameters } from '../../static/utility';

import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useChainId
} from 'wagmi';
import { getAddress, zeroAddress, isAddress, etherUnits, parseEther, toHex, decodeEventLog } from 'viem'
import { useAbi } from '../../assets/abis/abi';

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import ImportAbi from '../inputs/ImportAbi';
//import {mintChannel} from '../static/blockchain';
import { postCampaignOffChainV1, postCampaignOffChain, getLoginStatus, getCampaign, putCampaignOffChain, putCampaignOffChainV1 } from '../../static/api';
import { useIsLoggedIn } from '../../hooks/useLoginState';

// Custom Components
import ErrorToast from '../ErrorToast';
import { CircularProgress, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import ValidateParameters from '../inputs/ValidateParameters';
import DateInput from '../inputs/DateInput';
import BigNumberInput from '../BigNumberInput';
import GoalInput from '../inputs/GoalManagement';
import BrandInput from '../inputs/BrandInput';
import moment from 'moment';
import MultipleNumberInput from '../inputs/MultipleNumberInput';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

function Version3Form({campaign}){
  const theme = useTheme();
  const navigate = useNavigate();
  const [campaignId, setCampaignId] = useState(0);
  const [campaignVersion, setCampaignVersion] = useState(0);
  const [campaignName, setCampaignName] = useState('');
  const handleNameChange = (event) => {
    setCampaignName(event.target.value);
  };
  const [campaignGoalCount, setCampaignGoalCount] = useState(3);
  const handleGoalCountChange = (event) => {
    setCampaignGoalCount(3);
    //setCampaignGoalCount(parseInt(event.target.value));
  };
  
  const [brandData, setBrandData] = useState({});
  const [campaignStart, setCampaignStart] = useState(BigInt(1687376338));
  const [campaignEnd, setCampaignEnd] = useState(BigInt(1687496338));
  const [hasBeenMinted, setHasBeenMinted] = useState(false);

  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [factoryAddress, billingAddress] = useEnvVariable();
  const { smartCampaignBilling } = useAbi();
  const { address } = useAccount();
  const chainId = useChainId();
  const [allArgs, setAllArgs] = useState([]);
  const [step, setStep] = useState(1);

  const [campaignCommunityGoal, setCampaignCommunityGoal] = useState([]);

  const addCommunityGoal = () => {
    setCampaignCommunityGoal([...campaignCommunityGoal, 0])
  }
  const updateCommunityGoal = (goal, index) => {
    const newGoals = campaignCommunityGoal.map((_goal, _index) => {
      if (_index === index) {
        return goal;
      }
      return _goal;
    });
    setCampaignCommunityGoal(newGoals);
  }
  const [campaignGoals, setCampaignGoals] = useState([]);
  const [goalStep, setGoalStep] = useState(0);
  const addGoal = () => {
    setCampaignGoals([...campaignGoals, {
      label: '',
      type: '',
      customerKey: 0,
      minValue: '',
      valueKey: 0,
      eventSignature: '',
      eventContractAddress: '',
      eventChainId: '',
      abi: '',
    }])
  }
  const updateGoal = (goal, index) => {
    const newGoals = campaignGoals.map((_goal, _index) => {
      if (_index === index) {
        return goal;
      }
      return _goal;
    });
    setCampaignGoals(newGoals);
  }

  const handleNext = async () => {
    setGoalStep((goalStep) => goalStep + 1);
  };

  const handleBack = async () => {
    setGoalStep((goalStep) => goalStep - 1);
  };
  useEffect(() => {
    if (hasBeenMinted) {
      setStep(2);
    }
  }, [hasBeenMinted])

  useEffect(() => {
    //console.log('version 3 load', campaignName)
    if (
      address && 
      campaignName && 
      campaignStart && 
      campaignEnd 
     ) {
      setAllArgs(
        [
          address,
          BigInt(campaignGoalCount),
          BigInt(campaignStart), 
          BigInt(campaignEnd)
        ]
      )
    }


  }, [address, campaignName, campaignStart, campaignEnd])

  const { config } = usePrepareContractWrite({
    address: billingAddress ? getAddress(billingAddress) : zeroAddress,
    abi: smartCampaignBilling?.abi,
    functionName: "mint",
    enabled: allArgs.length > 0 && billingAddress && campaign.campaignId === undefined,
    args: allArgs,
    onSuccess(data) {
      console.log('Prepare Success');
      console.log(allArgs);
    },
    onError(prepError) {
      //console.log('Prepare Error', prepError);
      //console.log(billingAddress, allArgs);
      setSubmissionLoading(false);
      if (prepError.message.includes('campaign cannot be longer than 3 months')){
        setErrorMessage('campaign cannot be longer than 3 months');
      }
      
    }
  }
  );
  const { write, status, error, data } = useContractWrite(config);
  const { isLoading, isSuccess, data : transactionResult } = useWaitForTransaction({
    hash: data?.hash,
  })

  const mintCampaign = async function () {
    try {
      setSubmissionLoading(true);
      setErrorMessage('');
      //console.log('minting campaign', campaignName, campaignCommand, campaignDestination);
      if (campaignName.length > 0) {
        await write();
      } else {
        console.log('invalid data');
        setSubmissionLoading(false);
        setErrorMessage('invalid data');
      }
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
    }
  }

  const updateCampaign = async function () {
    const _campaign = {
      campaignId: campaignId,
      name: campaignName, 
      startDate: campaignStart.toString(),
      endDate: campaignEnd.toString(),
      communityGoal: campaignCommunityGoal,
      goals: campaignGoals,
      brandData: brandData
    };
    setSubmissionLoading(false);
    await putCampaignOffChain(_campaign, billingAddress, campaignId);
    navigate('/'+campaignId)
  }


  useEffect(() => {
    const runAsync = async () => {
      console.log('transaction successful', status, error, transactionResult);
      const logs = transactionResult.logs.map((log, i) => {
        try {
          return decodeEventLog({
            abi: smartCampaignBilling?.abi,
            data: log.data, 
            topics: log.topics
          })
        } catch (err) {
          return false;
        }
      });
      //console.log('logs',logs);
      const realLog = logs.find((log) => {
        if (log && log.eventName === 'NewCampaign') {return log;}
        return false;
      });
      //console.log(realLog.args.campaignId.toString());
      
      const _campaign = {
        campaignId: parseInt(realLog.args.campaignId.toString()),
        name: campaignName, 
        startDate: campaignStart.toString(),
        endDate: campaignEnd.toString()
      };
      setHasBeenMinted(true);
      setSubmissionLoading(false);
      await postCampaignOffChain(_campaign, billingAddress, data.hash);
      navigate('/edit/'+toHex(campaign.campaignId))
      //setCampaignId(parseInt(campaign.campaignId));
    }
    if (isSuccess) {
      runAsync();
    } else if (error) {
      console.log('transaction failed');
      setErrorMessage(error.message);
      setSubmissionLoading(false);
    }

  }, [isSuccess, error])
  
  useEffect(() => {
    console.log('campaignHasChanged', campaign);
    if (Object.keys(campaign).length > 0) {
      console.log('setting version');
      setCampaignVersion(3);      
      setCampaignName(campaign.name as string);
      setCampaignStart(BigInt(moment(campaign.startDate).unix()));
      setCampaignEnd(BigInt(moment(campaign.endDate).unix()));
      if (campaign.goals) {
        setCampaignGoals(campaign.goals);
      }
      setCampaignId(parseInt(campaign.campaignId as string));
      setCampaignCommunityGoal(campaign.communityGoal);
      setBrandData(campaign.brandData);
      setHasBeenMinted(true);
    }
  }, [campaign])

  useEffect(() => {
    if (campaignGoals.length > 1) {
      handleNext()
    }
  }, [campaignGoals.length])

  function LoadingSection () {
    return (
      <Box display={'flex'}>
        <Box sx={{ display: 'inline-flex', mx: 'auto' }} py={'25vh'}>
          <CircularProgress />
        </Box>

      </Box>
    )
  }

  return (
    <Box sx={{}} p={2}>

      {submissionLoading &&
        <LoadingSection />
      }

      {!submissionLoading && step === 1 && 
        <Box sx={{ alignItems: 'center' }} p={2}>
          <Box maxWidth="max" sx={{ flexDirection: 'row', p: 2 }}>
            <Box sx={{ justifyContent: 'center' }}>
              <TextField
                id="campaignName"
                helperText="Name"
                placeholder='Campaign Name'
                variant="standard"
                fullWidth
                value={campaignName}
                onChange={handleNameChange}
                sx={{ display: 'block', mx: "auto" }}
              />

              <DateInput 
                currentValue={campaignStart} param={'Campaign Start Date'} setParam={setCampaignStart}
              />
              <DateInput 
                currentValue={campaignEnd} param={'Campaign End Date'} setParam={setCampaignEnd}
              /> 

              {/*<TextField
                id="campaignGoals"
                helperText="Number of Campaign Goals"
                placeholder='3'
                variant="standard"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                sx={{color: 'white !important', display: 'block', mx: "auto"}}
                value={campaignGoalCount}
                onChange={handleGoalCountChange}
              />*/}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              sx={{ mr: 1 }}
              component={Link} to={'/dashboard'}
            >
              Cancel
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {campaign._id ?  
              <LoadingButton
                component="span"
                loading={submissionLoading}
                variant="contained"
                onClick={updateCampaign}
              >
                Update Campaign
              </LoadingButton> : <LoadingButton
                component="span"
                loading={submissionLoading}
                disabled={!write}
                variant="contained"
                onClick={mintCampaign}
              >
                Mint Campaign
              </LoadingButton>
            }              
          </Box>
        </Box>
      }
      {!submissionLoading && step === 2 && 
        <Box sx={{ alignItems: 'center' }} p={2}>
          <Box maxWidth="max" sx={{ flexDirection: 'row', p: 2 }}>
            <Grid container>
              <Grid xs={12} sm={6}>
                <TextField
                  id="campaignName"
                  helperText="Name"
                  placeholder='Campaign Name'
                  variant="standard"
                  fullWidth
                  value={campaignName}
                  onChange={handleNameChange}
                  sx={{ display: 'block', mx: "auto" }}
                />

                
                {/*<MultipleNumberInput existingValues={campaignCommunityGoal} updateValues={setCampaignCommunityGoal} title={'Community Milestones'}/>*/}
                <BrandInput brandData={brandData} update={setBrandData} />
              </Grid>
              <Grid xs={12} sm={6}>
                <Box sx={{ px:2 }}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography color="text.primary"  variant={'h6'}>Campaign Goals</Typography>
                    <IconButton onClick={addGoal} disabled={campaignGoals.length === null} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Stack>
                  {campaignGoals.length > 0 && campaignGoals.map((goal, index) => {
                    return (
                      <div key={index}>
                        {index === goalStep && 
                          <GoalInput goal={goal} index={index} updateGoal={updateGoal}/>
                        }
                      </div>
                      
                    );
                  })}
                  <MobileStepper
                    variant="dots"
                    steps={campaignGoals.length}
                    position="static"
                    activeStep={goalStep}
                    sx={{ minWidth: 400, flexGrow: 1 }}
                    
                    nextButton={
                      <Button size="small" onClick={handleNext} disabled={goalStep === campaignGoals.length-1 || campaignGoals.length === 0}>
                        Next
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={goalStep === 0}>
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              component={Link} to={'/'+toHex(campaignId)}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <LoadingButton
              component="span"
              loading={submissionLoading}
              disabled={!hasBeenMinted}
              variant="contained"
              onClick={updateCampaign}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      }
      
    </Box>
  )
}

export default Version3Form;