import {env} from './static/constants';
import { useState } from 'react';
import App from './App';
import { wagmiClient, chains} from './services/wagmiClient';
import { WagmiConfig } from "wagmi";
import reportWebVitals from './reportWebVitals';
import {
  EthereumClient,
} from "@web3modal/ethereum";
import fontColorContrast from 'font-color-contrast';
import { Web3Modal } from "@web3modal/react";
// Web3Modal Ethereum Client h
const ethereumClient = new EthereumClient(wagmiClient, chains);

export default function Loader() {
  const [theme, setTheme] = useState({
    '--w3m-accent-color': '#5048E5',
    '--w3m-accent-fill-color': "#FFFFFF",
    '--w3m-background-color' : '#5048E5'
  });
  const [mode, setMode] = useState('dark');

  function setCustomTheme(newTheme) {
    console.log(fontColorContrast(newTheme.primary))
    setTheme({
      '--w3m-accent-color': newTheme.primary || '#5048E5',
      '--w3m-accent-fill-color' : newTheme.primary ? fontColorContrast(newTheme.primary): "#FFFFFF",
      '--w3m-background-color' : newTheme.primary || '#5048E5'
    })
    if (newTheme.base_mode) {
      setMode(newTheme.base_mode);
    }
  }
  
  return (
    <>
      <WagmiConfig config={wagmiClient}>
        <App updateTheme={setCustomTheme}/>
      </WagmiConfig>
      
      {
        <Web3Modal
          projectId={env.REACT_APP_WALLET_CONNECT_PROJECT_ID}
          ethereumClient={ethereumClient}
          themeVariables={theme}
          themeMode={mode === "dark" ? 'dark' : 'light'}
        />
      }
    </>
  )
}