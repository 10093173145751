import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Typography } from '@mui/material';

const columns = [
  { field: 'points', headerName: 'Points', flex: 1 },
  { field: 'address', headerName: 'Address', flex: 3 },
];

const CampaignLeaderboard = ({ data }) => {
  const sortedData = [...data].sort((a, b) => b.points - a.points);

  return (
    <Stack direction={'row'} justifyContent={'center'} pb={10}>
      <Box sx={{height: '50vh', width: '50vw'}}>
        <Typography color={'text.primary'} variant={'h5'} pb={2}>Leaderboard</Typography>
        <DataGrid autoPageSize columns={columns} rows={sortedData} getRowId={(row) => {return row.address}}/>
      </Box>
    </Stack>
  );
};

export default CampaignLeaderboard;
