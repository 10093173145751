import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount, useConnect, useNetwork, useSignMessage, useWalletClient } from 'wagmi';
import { Web3Button, useWeb3ModalTheme } from "@web3modal/react";
import { SiweMessage } from 'siwe';
import { getLoginNoonce, getLoginStatus, getLogout, getToken, postLoginVerification, postToken, putToken } from '../static/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogTitle, IconButton, Paper, TextField, CircularProgress, useMediaQuery, useTheme, Stack } from '@mui/material';
import BigNumberInput from './BigNumberInput';
import ErrorToast from './ErrorToast';
import { Refresh } from '@mui/icons-material';
import { useIsLoggedIn } from '../hooks/useLoginState';
import { useNavigate } from 'react-router-dom';

const domain = window.location.host;
const origin = window.location.origin;
const LoginButton = ({store}) => {
  const theme2 = useTheme();
  const navigate = useNavigate();
  const mdUp = useMediaQuery(() => theme2.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const { loggedIn, reload: validateLogin } = useIsLoggedIn();
  const { connector: activeConnector, isConnected, address } = useAccount();
  const { data, isError, isLoading, isSuccess, signMessageAsync } = useSignMessage()
  const { chain } = useNetwork();
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { theme, setTheme } = useWeb3ModalTheme();
  
  // Set modal theme
  /*setTheme({
    themeMode: "dark",
    themeVariables: {
      '--w3m-accent-color': theme2.palette.primary.main
    }
  });*/

  useEffect(() => {
    validateLogin();
    setTheme({
      themeMode: "dark",
      themeVariables: {
        '--w3m-accent-color': theme2.palette.primary.main
      }
    });
  }, []);


  function createSiweMessage (statement, nonce) {
    const siweMessage = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: '1',
      chainId: chain.id,
      nonce: nonce.data
    });
    return siweMessage.prepareMessage();
  }

  async function login () {
    try {
      const nonce = await getLoginNoonce();
      const siwe = createSiweMessage('Sign in with Ethereum to the app.', nonce);
      const signature = await signMessageAsync({
        message: siwe
      });
      const result = await postLoginVerification(siwe, signature);
      //validateLogin();
      window.location.reload()
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      {!isConnected && 
        <Web3Button />
      }
      {isConnected && !loggedIn && 
        <Button variant='contained' onClick={login}>Login</Button>
      }
      {isConnected && loggedIn && 
        <>
          <Box sx={{my: 0, mx: 'auto'}}>
            <IconButton aria-label="openSettings" onClick={() => {navigate('/settings')}}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </>
      }
    </>
    
  )
}

export default LoginButton;
