import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { TextField, MenuItem, Snackbar, Box, Menu, Button } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const BigNumberInput = ({ label, existingValue, callback, fullwidth, variant }) => {
  const [inputValue, setInputValue] = useState("0");
  const [selectedUnit, setSelectedUnit] = useState("wei");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (existingValue) {
      setInputValue(existingValue.toString())
    }
  }, [existingValue])

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (isNaN(value)) {
      setInputValue("");
    } else {
      setInputValue(value);
      convertAndCallback(value, selectedUnit);
    }
  };

  const handleUnitChange = (unit) => {
    //const unit = event.target.value;
    setSelectedUnit(unit);
    setAnchorEl(null);
    convertAndCallback(inputValue, unit);
  };

  const convertAndCallback = (value, unit) => {
    try {
      const bigNumberValue = ethers.utils.parseUnits(value, unit);
      callback(BigInt(bigNumberValue.toHexString()));
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  return (
    <Box display={'flex'}>
      <TextField
        label={ethers.BigNumber.isBigNumber(inputValue) ? "Please enter a number" : ""}
        value={inputValue}
        onChange={handleInputChange}
        error={ethers.BigNumber.isBigNumber(inputValue)}
        helperText={label}
        fullWidth={fullwidth}
        variant={variant}
        InputProps={{
          endAdornment: 
            <>
              <Button variant="text" color={'inherit'} onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                {selectedUnit}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >  
                <MenuItem value="wei" onClick={() => {handleUnitChange('wei')}}>wei</MenuItem>
                <MenuItem value="gwei" onClick={() => {handleUnitChange('gwei')}}>gwei</MenuItem>
                <MenuItem value="ether" onClick={() => {handleUnitChange('ether')}}>ether</MenuItem>
              </Menu>
            </>
          ,
        }}
      />
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default BigNumberInput;
