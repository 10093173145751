// @ts-nocheckhj
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { toHex } from 'viem'

import { useIsLoggedIn } from '../hooks/useLoginState';

// Custom Components
import { useMediaQuery, useTheme } from '@mui/material';


function CampaignButton({ campaignId }) {
  const theme = useTheme();
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const {loggedIn, reload: validateLogin} = useIsLoggedIn();
  /**/

  return (
    <>
      <Box sx={{ my: 0, mx: 'auto' }}>
        {/*<IconButton aria-label="addRecord" onClick={newCampaign}>
          <AddIcon />
        </IconButton>*/}
       {loggedIn && campaignId === null && 
          <Button variant="contained" component={Link} to={'/create'}  sx={{
            display: 'block',
            borderRadius: "100rem", fontWeight: '600',
            fontSize: '80%', textTransform: 'none'
          }}>New Campaign</Button>
        }
        {loggedIn && campaignId !== null && 
          <IconButton aria-label="updateCampaign" component={Link} to={'/edit/'+toHex(BigInt(campaignId))} sx={{border: '1px solid #616161', borderRadius: 100, mx: 1}}>
            <EditIcon sx={{fontSize: 15}}/>
          </IconButton>
        }
      </Box>

    </>
  );
}

export default CampaignButton;


