import {Badge, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Container, Divider, Icon, IconButton, Link, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import {ethers, BigNumber} from 'ethers';
import {
  useAccount,
  useContractRead,
  useNetwork
} from 'wagmi';
import converter from 'json-2-csv';
import { avalanche, bsc, mainnet, polygon, optimism, arbitrum, polygonMumbai } from 'wagmi/chains';
import { getAddress, toHex, zeroAddress } from 'viem'
import { env, useEnvVariable } from '../static/constants';
import { CampaignOutlined } from "@mui/icons-material";
import { useEffect, useState } from 'react';
import { pickColor, truncateString, capitalizeFirstLetter } from '../static/utility';
import CampaignHeader from '../components/CampaignHeader';
import { getCampaign, getCampaignResults, getCustomCampaignResults, getLeaderboardData, getLoginStatus } from '../static/api';
import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useAbi } from '../assets/abis/abi';

import { json2csv } from 'json-2-csv';
import TransactionVolumeByDate from '../components/Campaign/TransactionVolumeByDate';
import TopReferrers from '../components/Campaign/TopReferrers';
import TopCustomers from '../components/Campaign/TopCustomers';
import AddressOutput from '../components/AddressOutput';
import { useIsLoggedIn } from '../hooks/useLoginState';
import ProgressBar from '../components/Leaderboard/GoalGraph';
import CampaignLeaderboard from '../components/Leaderboard/CampaignLeaderboard';
//import Leaderboard from '../components/charts/Leaderboard';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const functionLabelsRegex = /\(([^)]+)\)/;

const _customColumns: GridColDef[] = [
  { field: 'duplicate', headerName: '', flex: .75, 
    renderCell: (params: GridRenderCellParams<String>) => (
      <Stack direction={'row'} justifyItems={'center'} alignItems={'space-between'} spacing={2}>
        {params.row.duplicate && 
          <Chip label="D" color={'error'} variant={'filled'} sx={{fontWeight: 'bold'}}/>    
        }
        {params.row.commission !== null && 
          <Chip label="$" color={'success'} variant={'filled'} sx={{fontWeight: 'bold'}}/>
        }
      </Stack>
      )
    ,
  },
  { field: 'transactionHash', headerName: 'Tx Link', flex: 1.5, 
    renderCell: (params: GridRenderCellParams<String>) => (
        <Link href={params.value as string} underline="hover" color={'GrayText'} target={'_blank'}>
          {truncateString((params.value as string).slice(-25), 25, 'left')}
        </Link>
      )
    ,
  },
  { field: 'name', headerName: 'Goal', flex: 1.2,
    renderCell: (params: GridRenderCellParams<String>) => (
    <>
      {params.row.type && 
        <Stack direction={'row'} justifyItems={'center'} alignItems={'space-between'} spacing={2}>
          <Chip 
            color={params.row.type === 'Event' ? 'warning' : params.row.type === 'API' ? 'info' : 'success' } 
            label={params.row.type}
          />
          <Typography variant={'body1'}>{params.value as string}</Typography>    
        </Stack>
      }
    </>
    )
  },
  { field: 'createdDate', headerName: 'Date', flex: 1.15, type: 'dateTime' },
  { field: 'customer', headerName: 'Customer', flex: 1.75,
    renderCell: (params: GridRenderCellParams<String>) => (
      <AddressOutput address={params.value as string} variant={'body2'} /> 
    )
  },
  { field: 'referrer', headerName: 'Referrer', flex: 1.75,
    renderCell: (params: GridRenderCellParams<String>) => (
      <AddressOutput address={params.value as string} variant={'body2'} /> 
    )
  },
];

const chainIdMap = {
  80001: polygonMumbai,
  42161: arbitrum,
  137: polygon, 
  1: mainnet,
  43114: avalanche, 
  56: bsc,
  10: optimism
}

export default function Campaign ({store}) {
  const theme = useTheme();
  const {chain} = useNetwork();
  const { campaignId } = useParams();
  const { loggedIn } = useIsLoggedIn();
  const [customColumns, setCustomColumns] = useState([] as GridColDef[]);
  const [customResults, setCustomResults] = useState([] as GridRowsProp);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [communityGoal, setCommunityGoal] = useState([]);
  const [mongoId, setMongoId] = useState('');
  const [listener, setListener] = useState('');
  const [rawData, setRawData] = useState([]);
  const navigate = useNavigate();
  const {address} = useAccount();
  const [factoryAddress, billingAddress] = useEnvVariable();
  const [loading, setIsLoading] = useState(true);
  const [sorting, setIsSorting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [_campaignAddress, setCampaignAddress] = useState('');
  const [command, setCommand] = useState('');
  const [goalMap, setGoalMap] = useState({});
  const [allGoals, setAllGoals] = useState([]);
  const {smartCampaignBilling} = useAbi();

  const getCampaignId = (argType) => {
    try {
      if (argType === 'bigNum') {
        return BigInt(campaignId)
      } else if (argType === 'hex') {
        return toHex(BigInt(campaignId));
      } else if (argType === 'num') {
        return campaignId ;
      } else {
        return '';
      }      
    } catch (err) {
      return '';
    }
   
  }
  const { data: tokenOwner, refetch: ownershipFetch } = useContractRead({
    address: billingAddress ? getAddress(billingAddress) : zeroAddress,
    abi: smartCampaignBilling?.abi,
    functionName: 'isOwner',
    args: [
      address, 
      campaignId
    ],
    enabled: billingAddress.length > 0 && campaignId !== null && false
  })
  
  useEffect(() => {
    //console.log(campaignId, billingAddress);
    if (campaignId !== '' && campaignId !== null && billingAddress && smartCampaignBilling) {
      ownershipFetch();
    }
  }, [campaignId, billingAddress, smartCampaignBilling])

  useEffect(() => {
    if ( billingAddress) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [tokenOwner, address, billingAddress]);

  const _getCustomResults = async (_id, goals) => {
    try {
      const results = await getCustomCampaignResults(_id);
      console.log('custom results :', results);
      // Construct columns from command parameters
      /*const _functionLabels = functionLabelsRegex.exec(command as string);
      const functionLabels = _functionLabels[1].split(',');
      setCustomColumns(functionLabels.reduce((returnData, label, i) => {
        return [...returnData, { field: label+i, headerName: label, flex: 1 } as GridColDef];
      }, [..._customColumns]));*/
      setCustomColumns([..._customColumns]);
      
      //console.log(results);
      setRawData(results);
      setIsLoading(false);
      if (results.length > 0) {
        setIsSorting(true);
      }
      
    } catch (err) {
      console.log(err);
    }
  }

  const validateLogin = async () => {
    try {
      await getLoginStatus();
      setErrorMessage('');
    } catch (err) {
      setErrorMessage('Login with Ethereum to access data');
    }
  }
  useEffect(() => {
    //console.log(campaignId);
    try {
      if (!BigInt(campaignId)) {
        //setIsError(true);
      } else {
        setIsError(false);
      }
      validateLogin();
    } catch (err) {
      //console.log(err);
      setIsError(true);
    }
    
  }, [campaignId])
  
  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(campaignId, billingAddress);
      //console.log(camp);
      if (camp) {
        setCommand(camp.command as string);
        setListener(camp.listenerAddress as string);
        setMongoId(camp._id as string);
        setCommunityGoal(camp.communityGoal)
        
        const goals = camp.goals.map((val) => {
          return val;
        })
        /*const _goals = goals.reduce((finalVal, goal) => {
          return {
            ...finalVal,
            [goal._id] : goal
          }
        })*/
        console.log('goals', goals);
        setAllGoals(goals);

        await _getCustomResults(camp._id, goals);

        const leaderboardDetails = await getLeaderboardData(address, camp._id);
        setLeaderboardData(leaderboardDetails.leaderboard);
      }
      
    }  
    if (isValid && loggedIn) {
      setLeaderboardData([])
      setCommunityGoal([])
      setCommand('')
      setAllGoals([])
      runAsync();
    }
  }, [isValid, campaignId, loggedIn, chain])
  
  useEffect(() => {
    if (_campaignAddress) {
      ownershipFetch();
    }
  }, [_campaignAddress])

  useEffect(() => {
    if (rawData.length > 0) {
      // Create map of transactionids to boolean
      const _transactionHasIsDupe = rawData.reduce((finalVal, res) => {
        if (res.transactionHash && res.customer) {
          if (Object.prototype.hasOwnProperty.call(finalVal, res.transactionHash+res.customer)) {
            return {...finalVal, [res.transactionHash+res.customer] : true};
          } else {
            return {...finalVal, [res.transactionHash+res.customer] : false};
          }
        } else {
          return finalVal;
        }
      }, {})

      // Build Campaign Results adding additional labels from calldata
      const _customResults = rawData.map((event, i) => {
        //console.log(event, goalMap)
        const goal = allGoals.find((val, i) => {
          return val._id === event.parentId
        });
        //console.log(goal);
        const goalChainId = goal?.eventChainId || goal?.functionChainId;
        const commission = goal && event.calldata && goal.referrerCommissionPercentage !== 0 ? event.calldata[goal.valueKey]*(goal.referrerCommissionPercentage/100) : null;
        return {
          id: i, 
          createdDate : moment(event.createdDate).toDate(),
          transactionHash : event.transactionHash && goalChainId ? chainIdMap[goalChainId].blockExplorers.default.url + '/tx/' + event.transactionHash : '',
          referrer: event.referrer, 
          customer: event.customer, 
          type: goal && goal.type ? goal.type : 'No Type',
          name: goal && goal.label ? goal.label : 'No Label',
          duplicate: event.transactionHash ? _transactionHasIsDupe[event.transactionHash+event.customer] : false,
          commission
        }
      }) as GridRowsProp;
      // set useStates
      //setRawData(results);
      //console.log('customResults', _customResults, allGoals);
      setCustomResults(_customResults);
      setIsSorting(false);
    }
    
  },[rawData])

  const goBack = () => {
    navigate('/dashboard')
  }
  
  const exportData = async () => {
    //console.log(rawData);
    const data = JSON.parse(JSON.stringify(customResults));
    const csvString = await converter.json2csvAsync(data);
    downloadFile('smart-campaigns-extract.csv',  'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvString));
  }

  const downloadFile = (fileName, urlData) => {

    var aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;

    var event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  }

  return (
    <>
      {campaignId !== null && !isError && 
        <Grid
          container
          sx={{
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex"
          }}
          spacing={0}
        >
          <Grid xs={2} md={1} sx={{pb:0}} >
            <IconButton sx={{m: 2, mb: 0}} aria-label="back" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid xs={10} md={11}></Grid>
          
          <Grid xs={12} >
            <CampaignHeader campaignId={campaignId} rawData={null}></CampaignHeader>            
          </Grid>
          {/*<Grid xs={12} md={4} py={2}>
            <Box p={4}>
              <ProgressBar progress={leaderboardData.reduce((progress, data) => {
                return progress + data.points;
              },0)} goal={communityGoal[0]} />
            </Box>
          </Grid>*/}
          <Grid xs={12} md={6} p={2}>
            <TopReferrers customResults={customResults}/>
          </Grid>
          <Grid xs={12} md={6} p={2}>
            <CampaignLeaderboard data={leaderboardData}/>
          </Grid>
          <Grid xs={12} p={2}>
            {!loading && !sorting && 
              <>
                <Stack direction={'row'} spacing={2} justifyContent='space-between'>
                  <Typography variant={'h6'} py={0} px={3} color={'text.primary'}>Action Log</Typography>
                  <Button variant="contained" color="success" sx={{mr:3}} onClick={exportData}>Export</Button>                
                </Stack>
                <DataGrid rows={customResults} columns={customColumns} sx={{border: 0, p:2}} autoHeight {...customResults}/>
              </>
            }

            {sorting &&  
              <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent='center'>
                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                  <Typography variant='body1' color={'text.primary'}>Sorting</Typography>
                  <CircularProgress />
                </Stack>
              </Stack>
            }
            
            {loading && 
              <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent='center'>
                <CircularProgress />
              </Stack>
            }
            
          </Grid>         
        </Grid>
      }
      {isError && 
        <Grid
          container
          sx={{
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
          spacing={0}
          alignItems="center"
          justifyItems="center"
        >
          
          <Typography color={'text.primary'}>Invalid Campaign Id</Typography>
        </Grid>
      }
      
    </>
  )
}