import { JsonFragment } from '@ethersproject/abi';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

function AbiSelector({onChange, existingAbi, existingCommand, type}) {
  const [jsonData, setJsonData] = useState({});
  const [_interface, setInterface] = useState({} as any);
  const [options, setOptions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState('');

  const handleFunctionSelect = (event) => {
    setSelectedFunction(event.target.value);
    onChange(event.target.value);
  }

  const handleClearClick = () => {
    setSelectedFunction('');
    onChange('');
  }

  useEffect(() => {
    if (jsonData['abi']) {
      const abiInt = new ethers.utils.Interface(jsonData['abi']);
      //console.log(abiInt);
      setOptions(Object.keys(abiInt.functions));     
      //console.log(abiInt);
    } else {
      setOptions([]);
    }
  }, [jsonData])

  useEffect(() => {
    if (existingAbi) {      
      //console.log('has existing abi', existingAbi)
      setJsonData({abi: existingAbi});
    }
  }, [existingAbi])

  useEffect(() => {
    if (existingCommand) {      
      setSelectedFunction(existingCommand);
    }
  }, [existingCommand])

  return (
    <>      
      {options.length > 0 && 
        <FormControl fullWidth>
          <Select
            labelId="FunctionSelect"
            id="FunctionSelectInput"
            value={selectedFunction}
            label={type + " Select"}
            onChange={handleFunctionSelect}
            variant={'standard'}
            endAdornment={<IconButton sx={{display: selectedFunction ? "": "none"}} onClick={handleClearClick}><ClearIcon/></IconButton>}
            sx={{"& .MuiSelect-iconStandard": {display: selectedFunction ? 'none': ''}}}
          >
            {options.map((opt, i) => {
              return (
                <MenuItem value={opt} key={i}>{opt}</MenuItem>
              )
            })}
          </Select>
          <Typography variant={'caption'} sx={{color: '#65748B'}}>{type + " Select"}</Typography>
        </FormControl>
      }
    </>
  );
}

export default AbiSelector;
