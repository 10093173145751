import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BookmarksIcon from '@mui/icons-material/BookmarksOutlined';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HouseIcon from '@mui/icons-material/House';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import SubscriptionsIcon from '@mui/icons-material/SubscriptionsOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import StorageIcon from '@mui/icons-material/Storage';
import GavelIcon from '@mui/icons-material/Gavel';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

import ThemeMode from './ThemeMode';
import AccountButton from './AccountButton';
import CampaignEdit from './CampaignButton';
import LogoDark from '../assets/images/logo-white.png';
import Logo from '../assets/images/logo-black.png';

import { AppBar, IconButton, ListItemButton, Toolbar, useMediaQuery } from '@mui/material';
import { useAccount } from 'wagmi';
import LoginButton from './LoginButton';

function Sidebar ({store, setTheme}) {
  const theme = useTheme();
  useEffect(() => {
  }, []);
  const {address} = useAccount();
  const [state, setState] = React.useState({
    desktop: true,
    mobile: false
  });

  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });

  const toggleMenu = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let artistLinkList = [
    {
      name: 'Dashboard',
      link: '/dashboard'
    }/*
    {
      name: 'Dashboard',
      link: '/dashboard'
    },
    {
      name: 'Subscriptions',
      link: '/channels/subscription'
    },
    {
      name: 'Channels',
      link: '/channels'
    },
    {
      name: 'Queue',
      link: '/queue'
    }*/
  ];

  const list = (anchor) => (
    <Box
      sx={{ width: 250, mt:2, bgcolor: 'transparent', overflowX: 'hidden' }}
      role="presentation"
    >
      <Box sx={{ mx: 'auto' }}>
        {theme.palette.mode === 'dark' &&           
          <Button variant="text" component={'a'} target="_blank" href="https://smartcampaigns.xyz" sx={{display: 'block'}}><img src={LogoDark} className="side-image" alt="menuLogo"/></Button>
        }
        {theme.palette.mode === 'light' && 
          <Button variant="text" component={'a'} target="_blank" href="https://smartcampaigns.xyz" sx={{display: 'block'}}><img src={LogoDark} className="side-image" alt="menuLogo"/></Button>
        }
        
      </Box>
      <List sx={{display: 'block', mt:2, mx:'auto'}}>
        
        { address && 
          <ListItem sx={{paddingLeft:0, paddingRight: 0, mx: 'auto', mb: 4}}>    
              <CampaignEdit campaignId={null}/>
          </ListItem>
        }
        {artistLinkList && address && 
          (artistLinkList.map((linkVal) => (
            <ListItemButton component={Link} to={linkVal.link} key={linkVal.name} sx={{ml: 4, ":hover" : {
              backgroundColor: 'rgba(0,0,0,0.3)'
            }}} >   
              <ListItemIcon sx={{mr:-2}}>
                {linkVal.name === 'Dashboard' && 
                 <HouseIcon />
                }
                {linkVal.name === 'Channels' && 
                 <StorageIcon />
                }
                {linkVal.name === 'Subscriptions' && 
                 <BookmarksIcon />
                }
                {linkVal.name === 'Messages' && 
                 <ChatBubbleOutlineIcon />
                }
                {linkVal.name === 'Governance' && 
                  <GavelIcon />
                }
                {linkVal.name === 'Queue' && 
                  <SubscriptionsIcon />
                }
              </ListItemIcon>
              <ListItemText primary={linkVal.name} sx={{mx: 'auto', color: 'white'}}/>
            </ListItemButton>
          )))
        }
        <ListItemButton component={'a'} target="_blank" href="https://smart-campaigns.gitbook.io/api-docs/" key="help-link"
          sx={{ml: 4, ":hover" : {
            backgroundColor: 'rgba(0,0,0,0.3)'
          }}}
        >   
          <ListItemIcon sx={{mr:-2}}>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary={'Help'} sx={{mx: 'auto', color: 'white'}}/>
        </ListItemButton>
        {
          /*<a href="https://www.tally.xyz/governance/eip155:80001:0x7B679f710cB210129074155e8989d6E667aA8FeB" target="_blank" rel="noreferrer"
            style={{
              textDecoration: 'none'
            }}
          >
            <ListItem button key="governance_link" sx={{pt:10}}>
              <ListItemIcon sx={{mr:-8}}>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Governance" sx={{color:theme.palette.text.primary}}/>
            </ListItem>
          </a>
        

          <Divider  sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              my: 20
            },
            mr:8, 
            borderWidth: 2, 
            backgroundColor: theme.palette.grey[200],      
            borderColor: theme.palette.grey[200]
          })}/>

          <ListItem button component={Link} to="/manage" key="manage-link" sx={{fontSize: '.5rem'}}>   
            <ListItemText primary="Settings" sx={{fontSize: '.5rem'}} className="secondary-links"/>
          </ListItem>
          
          */
        }
      </List>
      
      <Box sx={{
        position: 'absolute', 
        bottom:"1vh", 
        width:"100%", 
        alignItems: 'center', 
        justifyItems: 'center',
        textAlign: 'center'
      }}>
          { address && 
            <>
              {/** <ThemeMode valueChange={setTheme} /> */}
            </>            
          }
          <Box sx={{mb: 2}}>
            <LoginButton store={store} />
          </Box>
          {/*<AccountButton store={store} />*/}
      </Box>
    </Box>
  );
  
  return (
    <div style={{display:'flex'}}>
      {/*<MenuIcon onClick={toggleMenu('left', true)} sx={{verticalAlign: 'middle'}}></MenuIcon>*/}
      {mdUp && 
        <Drawer
          anchor={'left'}
          open={state['desktop']}
          variant="persistent"
          onClose={toggleMenu('desktop', false)}
          sx={{border: 'none'}}
          PaperProps={{
            elevation: 4,
            sx: {
              border: 'none', 
              boxShadow: 'none', 
              //backgroundColor: theme.palette.background.default
            }
          }}
        >
          {list('left')}
        </Drawer>
        
      }

      {!mdUp && 
        <>
          <AppBar position="static">
            <Toolbar sx={{  }}>
              <Button variant="text" component={'a'} target="_blank" href="https://smartcampaigns.xyz" sx={{}}><img src={LogoDark} className="icon-image" alt="menuLogo"/></Button>
            
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 'auto', mr: 2, alignSelf: 'end' }}
              >
                <MenuIcon onClick={toggleMenu('mobile', true)} sx={{verticalAlign: 'middle'}}></MenuIcon>
              </IconButton>
            </Toolbar>
            
          </AppBar>
          
          <Drawer
            anchor={'left'}
            open={state['mobile']}
            onClose={toggleMenu('mobile', false)}
            sx={{border: 'none'}}
            PaperProps={{
              elevation: 4,
              sx: {
                border: 'none', 
                boxShadow: 'none', 
                //backgroundColor: theme.palette.background.default
              }
            }}
          >
            {list('left')}
          </Drawer>
        </>
      }
    </div>
  )
}
export default Sidebar;