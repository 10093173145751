// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  Stack,
  Typography,
  Alert,
  AlertTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { env, useEnvVariable } from '../static/constants';
import { asExecuteArg } from '../static/utility';
import { useAbi } from '../assets/abis/abi';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useContractRead
} from 'wagmi';
import { getAddress, isAddress, zeroAddress } from 'viem'

//import { basicSvg } from '../../../../static/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import ImportAbi from './inputs/ImportAbi';
//import {mintChannel} from '../static/blockchain';
import { getCampaign, postCampaign } from '../static/api';

// Custom Components
import ErrorToast from './ErrorToast';
import { CircularProgress, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { ethers } from 'ethers';

function CampaignOwnerTransfer({campaignId}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const {smartCampaignBilling} = useAbi();
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createModalOpen, setModalOpen] = useState(false);
  const { address } = useAccount();
  const [factoryAddress, billingAddress] = useEnvVariable();
  const [_tokenName, setTokenName] = useState('');
  const [newOwner, setNewOwner] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [, setCampaignMetadata] = useState('');
  const handleNewOwner = (event) => {
    setNewOwner(event.target.value);
  };
  useEffect(() => {
    if (campaignId !== '' && campaignId !== null && createModalOpen && smartCampaignBilling && billingAddress) {
      ownershipFetch();
      setIsValid(true);
    }
  }, [campaignId, createModalOpen, smartCampaignBilling, billingAddress])

  const { data: isTokenOwner, refetch: ownershipFetch } = useContractRead({
    address: billingAddress ? getAddress(billingAddress) : zeroAddress,
    abi: smartCampaignBilling?.abi,
    functionName: 'isOwner',
    args: [
      address, 
      campaignId
    ],
    enabled: false
  })
  const { config : ownershipConfig } = usePrepareContractWrite({
    address: billingAddress !== `` ? getAddress(billingAddress) : zeroAddress,
    abi: smartCampaignBilling?.abi,
    functionName: 'safeTransferFrom',
    enabled: newOwner !== '' && billingAddress !== '' && isAddress(newOwner),
    args: [
      address,
      isAddress(newOwner) ? getAddress(newOwner) : zeroAddress,
      campaignId
    ],
    onSuccess(data) {
      console.log('Prepare Success approval');
    },
    onError(prepError) {
      console.log('onprep error approval');
      //console.log('console',ethers.utils.formatUnits(depositAmount, "gwei"))
      //console.log(ethers.utils.formatUnits(depositAmount, "gwei"), 'Prepare Error', prepError);
      setSubmissionLoading(false);
      setErrorMessage(prepError.message);
    }    
  }
  );
  const { write: ownershipWrite, status, error, data: ownershipData } = useContractWrite(ownershipConfig);
  const { isLoading, isSuccess: ownershipSuccess } = useWaitForTransaction({
    hash: ownershipData?.hash,
  })


  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(campaignId, billingAddress);
      console.log(camp);
      if (camp) {
        setTokenName(camp.name as string);
      }
      
    }  
    //console.log(command);
    if (isValid) {
      runAsync();
    }
  }, [isValid])
  
  /**/
  const transferOwner = async function() {
    try {
      setErrorMessage('');
      setCurrentStep(1);
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
    }
  }

  const confirmTransfer = async function() {
    try {
      setSubmissionLoading(true);
      await ownershipWrite();
      setErrorMessage('Transfer successful!');
      setTimeout(() => {
        closeNewModal();
      }, 2000)
    } catch (err) {
      console.log(err);
      setCurrentStep(0);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
    }
  }

  function transferCampaign() {
    setErrorMessage('');
    setModalOpen(true);
  }

  useEffect(() => {
    if (billingAddress) {
      ownershipFetch();
    }
  }, [billingAddress]);

  function closeNewModal() {
    console.log('closing modal');
    setErrorMessage('');
    setNewOwner('');
    setCurrentStep(0);
    setModalOpen(false);
    setSubmissionLoading(false);
  }
  

  return (
    <>
      
        {/*<IconButton aria-label="addRecord" onClick={newCampaign}>
          <AddIcon />
        </IconButton>
        <Button variant="contained" color={'info'} onClick={newCampaign} sx={{
          display: 'block', 
          borderRadius: "100rem", fontWeight:'600', 
          fontSize: '80%', textTransform: 'none'
        }}>Deposit</Button>*/}
        <IconButton aria-label="transferCampaign" onClick={transferCampaign} sx={{border: '1px solid #616161', borderRadius: 100, mx: 1}}>
          <PersonIcon sx={{fontSize: 15}}/>
        </IconButton>
      
      <Dialog open={createModalOpen} onClose={closeNewModal} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>
          Ownership Change
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeNewModal}
            sx={{float:'right'}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent >
          <Paper elevation={0} sx={{ maxWidth: 1200 , minWidth: mdUp ? '25rem' : '20rem'}} className="transparent-background">
              { !submissionLoading && 
                <>
                  { currentStep === 0 &&  
                    <Box sx={{alignItems: 'flex-end'}} p={2}>
                      <Box maxWidth="max" sx={{ flexDirection: 'row', p: 2}}> 
                        <Box sx={{ justifyContent: 'center'}}>
                          <TextField
                            id="campaignName"
                            helperText="Name"
                            placeholder='Campaign Name'
                            variant="standard"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                            value={_tokenName}
                            sx={{display: 'block', mx: "auto"}}
                          />  
                          <TextField
                            id="campaignOwner"
                            placeholder='0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512'
                            helperText="Old Owner"
                            variant="standard"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                            value={isTokenOwner ? address : 'You are not the campaign owner'}
                            sx={{display: 'block', mx: "auto"}}
                          />
                          <TextField
                            id="newOwner"
                            placeholder='0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512'
                            helperText="New Owner"
                            variant="standard"
                            fullWidth
                            value={newOwner}
                            onChange={handleNewOwner}
                            sx={{display: 'block', mx: "auto"}}
                          />  
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="inherit"
                          onClick={closeNewModal}
                          sx={{ mr: 1 }}
                        >
                          Cancel
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <LoadingButton
                          component="span"
                          loading={submissionLoading}
                          disabled={!ownershipWrite}
                          variant="contained"
                          onClick={transferOwner}
                        >
                          Submit
                        </LoadingButton>
                      </Box>
                    </Box>
                  }
                  { currentStep === 1 &&  
                    <Box sx={{alignItems: 'flex-end'}} p={2}>
                      <Box maxWidth="max" sx={{ flexDirection: 'row', p: 2}}> 
                        <Box sx={{ justifyContent: 'center'}}>
                          <Alert severity="warning">
                            <AlertTitle>Are you sure you want to change the ownership of this campaign?</AlertTitle>
                            <strong>This action cannot be undone!</strong>
                          </Alert>
                          <Typography sx={{mt:2}}>The new owner will be: </Typography>
                          <code style={{ textAlign: 'center', display: 'block', padding: 15, border: '1px solid #eee', backgroundColor: theme.palette.mode === 'light' ? '#eee' : '',  borderRadius: '1rem', marginTop: 10, marginBottom: 10}}>
                            {newOwner}
                          </code>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="inherit"
                          onClick={closeNewModal}
                          sx={{ mr: 1 }}
                        >
                          Cancel
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <LoadingButton
                          component="span"
                          loading={submissionLoading}
                          disabled={!ownershipWrite}
                          variant="contained"
                          onClick={confirmTransfer}
                        >
                          Submit
                        </LoadingButton>
                      </Box>
                    </Box>
                  }
                </>  
              }
              {submissionLoading && 
                <Box display={'flex'}>
                  <Box sx={{display: 'inline-flex', mx: 'auto' }} py={'25vh'}>
                    <CircularProgress />
                  </Box>
                  
                </Box>
              }
          </Paper>
        </DialogContent>
      </Dialog>
      
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </>
  );
}

export default CampaignOwnerTransfer;


