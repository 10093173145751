import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, Select, MenuItem, Button, FormControl, InputLabel, Paper, IconButton, Typography, Stack, Autocomplete } from '@mui/material';
import { ethers } from 'ethers';
import { Add } from '@mui/icons-material';
import { deepEqual } from '../../static/utility';

const operators = ['=', '>', '<', '>=', '<='];

const FilterBuilder = ({existingFilters, abi, signature, eventSignature, update}) => {
  const [loaded, setLoaded] = useState(false);
  const [filters, setFilters] = useState([{ input: '', operator: '=', output: '' }]);
  const [logicalOperator, setLogicalOperator] = useState('AND');
  const [inputOptions, setInputOptions] = useState([]);
  const [outputOptions, setOutputOptions] = useState([]);

  const addFilter = () => {
    setFilters([...filters, { input: '', operator: '=', output: '' }]);
  };

  const updateFilter = (index, field, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index][field] = value;
    setFilters(updatedFilters);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...filters];
    updatedFilters.splice(index, 1);
    setFilters(updatedFilters);
  };

  const getFunctionReturnProperties = async (contractAbi, functionSignature) => {
    try {
      const iface = new ethers.utils.Interface(contractAbi);
      const fragment = iface.getFunction(functionSignature);

      if (!fragment) {
        throw new Error('Function signature not found in ABI');
      }

      const returnTypes = fragment.outputs.map((output) => output.type);
      setOutputOptions(['',...returnTypes]);
    } catch (err) {
      console.log(err);
    }
    
  };



  useEffect(() => {
    const paramRegex = /(\w+)\s*(\w+)/g;
    const matches = [...eventSignature.matchAll(paramRegex)];
    const params = matches.map(match => match[0]);
    const _labels = params.slice(1);
    setInputOptions(['',..._labels]);
  }, [eventSignature])

  useEffect(() => {
    if (abi && signature) {
      getFunctionReturnProperties(abi, signature);
    }
  }, [abi, signature])

  useEffect(() => {
    if (filters && logicalOperator) {
      const payload = {
        filters,
        function: logicalOperator
      };
      //if (!deepEqual(payload,existingFilters)) {
        update(payload)
      //} 
    }
  }, [filters, logicalOperator])

  useEffect(() => {
    console.log('existingFilter', existingFilters);
    if (existingFilters.filters) {
      setFilters(existingFilters.filters);
      setLogicalOperator(existingFilters.function);
    }
  }, [])

  return (
    <Paper elevation={2} sx={{p: 2}}>
      <Typography variant={'body2'}>Build filter logic</Typography>
      <Stack direction={'row'} justifyContent={'space-between'} spacing={1} >        
        <Autocomplete
          disablePortal
          options={[
            '',
            'AND',
            'OR'
          ]}
          value={logicalOperator}
          onChange={(event: any, newValue: string | null) => {
            setLogicalOperator(newValue);
          }}
          sx={{minWidth: 100}}
          renderInput={(params) => <TextField {...params} helperText="Type" variant="standard" fullWidth />}
        />

        <IconButton onClick={addFilter} sx={{verticalAlign: 'middle'}} ><Add sx={{display:'flex'}} /></IconButton>
      </Stack>
      {filters.map((filter, index) => (
        <Stack direction={'row'} spacing={1} key={index}>
          <Autocomplete
            disablePortal
            options={inputOptions.map((o, i) => String(i))}
            getOptionLabel={(opt) => {
              if (opt === '0' || opt === '') return '';
              return inputOptions[Number(opt)] + '.' + String(Number(opt))
            }}
            value={filter.input}
            onChange={(event: any, newValue: string | null) => {
              updateFilter(index, 'input', newValue)
            }}
            sx={{minWidth: 150}}
            renderInput={(params) => <TextField {...params} helperText="Event" variant="standard" fullWidth />}
          />
          <FormControl sx={{minWidth: 50}}>
            <Select
              value={filter.operator}
              variant={'standard'}
              onChange={(e) => updateFilter(index, 'operator', e.target.value)}
            >
              {operators.map((operator) => (
                <MenuItem key={operator} value={operator}>
                  {operator}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            disablePortal
            options={outputOptions.map((o, i) => String(i))}
            getOptionLabel={(opt) => {
              if (opt === '0' || opt === '') return '';
              return outputOptions[Number(opt)] + '.' + String(Number(opt))
            }}
            value={filter.output}
            onChange={(event: any, newValue: string | null) => {
              updateFilter(index, 'output', newValue)
            }}
            sx={{minWidth: 150}}
            renderInput={(params) => <TextField {...params} helperText="Function" variant="standard" fullWidth />}
          />
          <IconButton size={'small'} color="error" onClick={() => removeFilter(index)}><DeleteIcon fontSize={'small'} /></IconButton>
          
        </Stack>
      ))}
    </Paper>
  );
};

export default FilterBuilder;
