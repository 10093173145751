import {Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Card, CardActions, CardContent, Chip, Container, Divider, Icon, IconButton, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import NetworkSelect from "./NetworkSelect"
import SocialIcons from "./SocialIcons"
import {ethers, BigNumber} from 'ethers';
import { getAddress, hexToNumber, isAddress, toHex, zeroAddress,  } from 'viem'
import moment from 'moment';
import {
  useAccount,
  useContractRead,
  useNetwork
} from 'wagmi';
import { env, useEnvVariable } from '../static/constants';
import { CampaignOutlined } from "@mui/icons-material";
import { useEffect, useState } from 'react';
import { pickColor } from '../static/utility';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import converter from 'json-2-csv';
import { getCampaign, getCustomCampaignResults } from '../static/api';
import FundCampaign from './FundCampaign';
import { useAbi } from '../assets/abis/abi'
import CampaignTokenBalance from './CampaignTokenBalance';
import CampaignEdit from './CampaignButton';
import CampaignOwnerTransfer from './CampaignOwnerTransfer';
import ApproveReferrers from './ApproveReferrers';

export default function CampaignHeader ({campaignId, rawData}) {
  const theme = useTheme();
  const {chain} = useNetwork();
  const [_tokenName, setTokenName] = useState('');
  const [_id, setId] = useState('');
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [goalCount, setGoalCount] = useState(0);
  const [factoryAddress, billingAddress] = useEnvVariable();
  const {address} = useAccount();
  const [isValid, setIsValid] = useState(false);
  const [goals, setGoals] = useState([]);
  
  const {smartCampaignBilling} = useAbi();

  const { data: tokenOwner, refetch: ownershipFetch } = useContractRead({
    address: billingAddress ? getAddress(billingAddress) : zeroAddress,
    abi: smartCampaignBilling?.abi,
    functionName: 'isOwner',
    args: [
      address, 
      campaignId
    ],
    enabled: billingAddress.length > 0 && campaignId !== null && false
  })
  
  useEffect(() => {
    //console.log(campaignId, billingAddress);
    if (campaignId !== '' && campaignId !== null && billingAddress && smartCampaignBilling) {
      ownershipFetch();
    }
  }, [campaignId, billingAddress, smartCampaignBilling])


  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(campaignId, billingAddress);
      //console.log(camp);
      if (camp) {
        setId(camp._id as string);
        setTokenName(camp.name.toString());
        setStartDate(camp.startDate as number);
        setEndDate(camp.endDate as number);
        setGoalCount(camp.goals.length);
        const referralGoals = camp.goals.filter((_goal) => {
          return _goal.type === 'Referral';
        });
        setGoals(referralGoals);
      }
      
    }  
    //console.log(command);
    if (isValid) {
      runAsync();
    }
  }, [isValid])


  useEffect(() => {
    console.log('tokenOwner',tokenOwner);
    //if ((tokenOwner || process.env.NODE_ENV === 'development') && billingAddress) {
    if (billingAddress) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [tokenOwner, address, billingAddress]);

  const exportData = async () => {
    //console.log(rawData);
    const rawData = await getCustomCampaignResults(_id)
    if (rawData.length > 0) {
      const csvData = rawData.map((event, i) => {
        return { 
          ...event, 
          transactionUrl : chain.blockExplorers.default.url + '/tx/' + event.transactionHash
        }
      });
      const csvString = await converter.json2csvAsync(csvData);
      downloadFile('smart-campaigns-extract.csv',  'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvString));
    }
  }

  const downloadFile = (fileName, urlData) => {

    var aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;

    var event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  }

  return (
    <>
      {isValid && 
      
        <Grid2 xs={12} md={12}  sx={{ }}>
          <Paper sx={{m:2, px:4, py:3, border: theme.palette.mode === 'light' ? '2px solid #ddd' : '' }}  elevation={4}> 
              {
                //<Chip label={BigInt(campaignId).toHexString()} variant={theme.palette.mode === 'dark' ? 'outlined': 'filled'}/>
              }
              <Stack 
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{mb: 1}}
              >
                <Box sx={{display: 'flex'}}>
                  <Typography variant="h6" sx={{lineHeight: 1.8}}>
                    {_tokenName}
                  </Typography>
                  
                  { tokenOwner &&         
                    <CampaignEdit campaignId={campaignId} />         
                  }
                </Box>
                <Box>
                  {tokenOwner && 
                    <CampaignOwnerTransfer campaignId={campaignId} />
                  }
                  
                  { false && 
                    <Chip variant={'filled'} color={'primary'} sx={{fontWeight: 600, mr:1}} label={'Airdrop'}></Chip>
                  }
                  <Chip variant={theme.palette.mode === 'dark' ? 'outlined' : 'outlined'} label={toHex(BigInt(campaignId))}></Chip>
                </Box>
              </Stack>
              
              <Divider variant={'fullWidth'} sx={{borderColor: pickColor(), borderWidth: 2, mb: 2}}></Divider>
              
              <TextField
                label="Campaign Factory Address"
                fullWidth
                value={billingAddress}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
            
              <TextField
                label="Start Date"
                fullWidth
                value={moment.unix(startDate).format('MM/DD/YYYY')}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
              {
                <TextField
                  label="End Date"
                  fullWidth
                  value={moment.unix(endDate).format('MM/DD/YYYY')}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              }
              {
                <TextField
                  label="Campaign Id"
                  fullWidth
                  value={hexToNumber(toHex(BigInt(campaignId)))}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              }
              {
                <TextField
                  label="Campaign Goals"
                  fullWidth
                  value={goalCount}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                />
              }
              {
                /*<CampaignTokenBalance campaignAddress={_campaignAddress} rewardAddress={rewardAddress}/>*/
              }       
              
              <Stack direction="row" spacing={2} sx={{mt: 2}} justifyContent='space-between'>
                <Button variant="contained" color="info" disabled>Airdrop</Button>
                {/*<Button variant="contained" component={Link} to={'/example/'+toHex(BigInt(campaignId))} color={'secondary'} >Try It!</Button>*/}
                {
                  /*<FundCampaign campaignId={campaignId} />*/
                }
                <ButtonGroup variant="contained" aria-label="split button">
                  {/*<Button variant="contained" color="primary" component={Link} to={'/leaderboard/'+toHex(BigInt(campaignId))}>Leaderboard</Button>*/}
                  {
                    goals.map((goal, i) => {
                      return <Button variant="contained" color="primary" component={Link} to={'/referral/'+toHex(BigInt(campaignId))+'/'+goal.label}>{goal.label}</Button>
                    })
                    
                  }
                  
                </ButtonGroup>
                
              </Stack>      
          </Paper>
        </Grid2>
      }
    </>
    
  )
}
