import { ethers } from "ethers";

export const makeId = (length) =>  {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
  }
 return result;
}

export const asExecuteArg = (functionName, argTypes, ...args) => {  
  let signature = `function ${functionName}(${argTypes})`
  let ABI = [signature];
  let _interface = new ethers.utils.Interface(ABI);
  let output = _interface.encodeFunctionData(functionName, args);
  return output;
}

export const pickColor = () => {
  const sourceList = ['#716AEB', '#FFF35C', '#A161EA', '#FA5A7F', '#94F759', '#FF935C'];
  return sourceList[Math.floor(Math.random() * sourceList.length)];
}

export const truncateString = (str, num, area) => {
  if (str.length >= num) {
    if (area === 'right') {
      return str.slice(0, num) + "...";
    }
    return "..." + str.slice(0, num);
  } else {
    return str;
  }
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const countParameters = (inputString) => {
  const regex = /\(([^)]*)\)/;
  const match = inputString.match(regex);

  if (match) {
    const params = match[1].split(',');
    return params.length;
  } else {
    console.error("Invalid input format");
    return 0;
  }
}

export const getParameters = (inputString) => {
  const regex = /\(([^)]*)\)/;
  const match = inputString.match(regex);

  if (match) {
    const params = match[1].split(',');
    return params;
  } else {
    console.error("Invalid input format");
    return [];
  }
}

export const getValue = (result) => {
  if (result.hasOwnProperty['uint256']) {
    return result['uint256'];
  }
  return Array.from(Array(16).keys()).reduce((f, c, i) => {
    if (result['uint256'+i]) {
      //console.log('has property', 'uint256'+i)
      return Number(result['uint256'+i]);
    }
    //console.log(result['uint2561'])
    //console.log('does not have property', 'uint256'+i, result['uint256'+i])
    return f
  }, 0)
  
}

export const isBlank = (str) => {
  return (!str || str.length === 0 );
}

export const isNotBlank = (str) => {
  return (str && str.length > 0 );
}

export const deepEqual = (x, y) => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}