import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { VisibilityOff, Visibility, AccountCircle } from '@mui/icons-material';

const SignatureParser = ({ type, signature, currentValues, updateValues }) => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const paramRegex = /(\w+)\s*(\w+)/g;
    const matches = [...signature.matchAll(paramRegex)];
    //console.log(matches);
    const params = matches.map(match => match[0]);
    //console.log(params);
    const _labels = params.slice(1);
    const _values = _labels.map(() => '');
    setLabels(_labels);
  }, [signature])

  useEffect(() => {
    const mergedValues = labels.map((label, i) => {
      if (currentValues && currentValues[i] && currentValues[i] !== '') {
        return currentValues[i];
      }
      return '';
    })
    setValues(mergedValues);
  }, [currentValues, labels])

  const handleInputChange = (index, event) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
    updateValues(newValues);
  };

  const setInputCustomer = (index) => {
    const newValues = [...values];
    newValues[index] = '<customer>';
    setValues(newValues);
    updateValues(newValues);
  };

  

  return (
    <Paper elevation={2} sx={{p: 2}}>
      <Typography variant={'body2'}>{type}</Typography>
      {values.map((value, index) => (
        <TextField
          key={index}
          label={labels[index]}
          value={value}
          onChange={(event) => handleInputChange(index, event)}
          variant="standard"
          fullWidth
          sx={{display: 'block', mx: "auto"}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {setInputCustomer(index)}}>
                  <AccountCircle />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ))}
    </Paper>
  );
};

export default SignatureParser;
