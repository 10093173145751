import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import BigNumberInput from '../BigNumberInput';
import { env } from '../../static/constants';

// Custom Components
import ErrorToast from '../ErrorToast';
import { Paper } from '@mui/material';
import { ethers } from 'ethers';

function ParamInput({param, setParam, index}) {
  const [errorMessage, setErrorMessage] = useState('');

  const [paramInput, setParamInput] = useState('');
  const handleParamInput = (event) => {
    setParamInput(event.target.value);
    setParam(event.target.value, index);
  };

  const handleCallbackInput = (value) => {
    console.log(value);
    setParam(value, index);
  }
  
  
  useEffect(() => {
   //
  }, [param]);
  return (
    <Box display={'flex'}>
      { param.includes('int') && 
        <BigNumberInput variant={'filled'} label={param} fullwidth={false} callback={handleCallbackInput} existingValue={0} />
      }
      { !param.includes('int') && 
        <TextField
          placeholder=''
          helperText={param}
          variant="filled"
          
          value={paramInput}
          onChange={handleParamInput}
          sx={{display: 'block', mx: "auto"}}
        />  
      }
      
      <ErrorToast errorMessage={errorMessage}></ErrorToast>
      
    </Box>
  );
}

export default ParamInput;


