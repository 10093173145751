import { useEffect, useState } from "react";
import moment from "moment";
import { ethers } from "ethers";
import { TextField, MenuItem, MobileStepper, Snackbar, Typography, Button, Tooltip, TooltipProps, tooltipClasses, styled, useTheme, IconButton } from "@mui/material";
import { useChainId } from "wagmi";
import React from "react";
import { getAddressHistory } from "../static/api";
import { AreaChart, CartesianGrid, YAxis, Area, XAxis } from "recharts";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { truncateString } from "../static/utility";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const AddressHistoryChart = ({data}) => {
  const theme = useTheme();
  const [computedData, setComputedData] = useState([]);
  const [currentCoinIndex, setCurrentCoinIndex] = useState(0);
  useEffect(() => {
    if (data.length > 0) {
      computeChartData()
    } 
  }, [data, currentCoinIndex]);

  const computeChartData = async () => {
    setComputedData(data[currentCoinIndex].holdings.map((holding) => {
      return {
        name: moment(holding['timestamp']).format('MM/DD/YYYY'),
        value: ethers.utils.formatEther(holding.close.balance)
      }
    }).reverse());
  }

  const handleNext = () => {
    setCurrentCoinIndex((currentCoinIndex) => currentCoinIndex + 1);
    computeChartData();
  };

  const handleBack = () => {
    setCurrentCoinIndex((currentCoinIndex) => currentCoinIndex - 1);
    computeChartData();
  };

  const renderLineChart = (
    <AreaChart width={450} height={225} data={computedData} margin={{ top: 5, right: 40, bottom: 5, left: 0 }}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
        </linearGradient>
      </defs>
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="name"/>
      <YAxis />
      {/*<Tooltip content={<CustomTooltip />} />*/}
      <Area dataKey="temperature" stroke="#8884d8" fill="#8884d8" />
      <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
      <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
    </AreaChart >
  );

  return (
    <>
      {data.length > 0 && 
        <>
          <Typography color={'text.primary'} variant={'h6'} textAlign={'center'} py={1}>{data[currentCoinIndex].contract_ticker_symbol}</Typography>
          {renderLineChart}
          <MobileStepper
            variant="dots"
            steps={data.length}
            position="static"
            activeStep={currentCoinIndex}
            sx={{ maxWidth: '100%', flexGrow: 1 }}
            nextButton={
              <IconButton size="small" onClick={handleNext} disabled={currentCoinIndex === data.length-1}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            }
            backButton={
              <IconButton size="small" onClick={handleBack} disabled={currentCoinIndex === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </IconButton>
            }
          /> 
        </>
      }
    </>
  )
}

const AddressOutput = ({ address, variant }) => {
  const [inputValue, setInputValue] = useState("1");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [data, setData] = useState([]);
  const chainId = useChainId();
  
  useEffect(() => {
    if (chainId) {
      setInputValue(chainId.toString())
    }
  }, [chainId]);

  useEffect(() => {
    const runAsync = async () => {
      const userHistory = await getAddressHistory(address.toLowerCase(), inputValue);
      if (userHistory.data) {
        setData(userHistory.data.items);
      }
    }
    if (isTooltipOpen) {
      runAsync();
    }
  }, [isTooltipOpen])
  return (
    <>
      {address && 
        <HtmlTooltip title={
          <React.Fragment>
            <Typography color="inherit" variant={'body2'} textAlign={'center'} py={1} fontWeight={'bold'}>{address}</Typography>
            {isTooltipOpen && 
              <AddressHistoryChart data={data}/>
            }
          </React.Fragment>
        } 
        onOpen={() => {setIsTooltipOpen(true)}}
        onClose={() => {setIsTooltipOpen(false)}}      
        >
          <Typography
            color={'text.primary'}
            variant={variant}
          >{truncateString(address.slice(-24), 24, 'left')}</Typography>
        </HtmlTooltip>
      }
      
      
    </>
  );
};

export default AddressOutput;
