
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { makeId } from '../../static/utility';
import {
  useAccount,
  useNetwork
} from 'wagmi';

import LogoDark from '../../assets/images/logo-white.png';
import {Box, Button, Card, CardActions, CardContent, Chip, ClickAwayListener, Container, Divider, Icon, IconButton, Link, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import {ethers, BigNumber} from 'ethers';
import { getAddress, toHex, zeroAddress } from 'viem'
import { env, useEnvVariable } from '../../static/constants';
import { getCampaign, getCampaignResults, getCustomCampaignResults, getLoginStatus, getLeaderboardData } from '../../static/api';

import { useIsLoggedIn } from '../../hooks/useLoginState';

import Countdown from '../../components/Leaderboard/Countdown';
import ProgressBar from '../../components/Leaderboard/GoalGraph';
import UserGoalProgress from '../../components/Leaderboard/UserGoalProgress';
import CampaignLeaderboard from '../../components/Leaderboard/CampaignLeaderboard';
import { Web3Button } from '@web3modal/react';;

export default function LeaderBoard({store}) {
  const {address} = useAccount();
  const [resetList, setResetList] = useState('');
  const theme = useTheme();
  const {chain} = useNetwork();
  const { campaignId } = useParams();
  const { loggedIn } = useIsLoggedIn();
  const [mongoId, setMongoId] = useState('');
  const [factoryAddress, billingAddress] = useEnvVariable();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [_campaignAddress, setCampaignAddress] = useState('');
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [goals, setGoals] = useState([]);
  const [goal, setGoal] = useState({
    label: '',
    type: '',
  });
  const [communityGoal, setCommunityGoal] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);

  const getCampaignId = (argType) => {
    try {
      if (argType === 'bigNum') {
        return BigInt(campaignId)
      } else if (argType === 'hex') {
        return toHex(BigInt(campaignId));
      } else if (argType === 'num') {
        return campaignId ;
      } else {
        return '';
      }      
    } catch (err) {
      return '';
    }
   
  }

  const validateLogin = async () => {
    try {
      await getLoginStatus();
      setErrorMessage('');
    } catch (err) {
      setErrorMessage('Login with Ethereum to access data');
    }
  }
  useEffect(() => {
    //console.log(campaignId);
    try {
      if (!BigInt(campaignId)) {
        //setIsError(true);
      } else {
        setIsError(false);
      }
      validateLogin();
    } catch (err) {
      //console.log(err);
      setIsError(true);
    }
    
  }, [campaignId])
  
  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(ethers.BigNumber.from(campaignId).toNumber(), billingAddress);
      //console.log(camp);
      if (camp) {
        //setCommand(camp.command as string);
        //setListener(camp.listenerAddress as string);
        setMongoId(camp._id as string);
        setStartDate(camp.startDate as number);
        setEndDate(camp.endDate as number);
        setGoals(camp.goals);
        setCommunityGoal(camp.communityGoal)

        const referralGoal = goals.find((_goal) => {
          return _goal.type === 'Referral';
        });
        setGoal(referralGoal);

        const leaderboardDetails = await getLeaderboardData(address, camp._id);
        setLeaderboardData(leaderboardDetails.leaderboard);
        setUserEvents(leaderboardDetails.userEvents);
        //console.log(leaderboardDetails);
      }
      
    }  
    //console.log(command);
    if (isValid) {
      runAsync();
    }
  }, [isValid, campaignId, loggedIn])

  useEffect(() => {
    //if (address) {
      setIsValid(true);
    /*} else {
      setIsValid(false);
    }*/
  }, [address])


  const Logo = () => {
    return (
      <img src={LogoDark} className="side-image" alt="menuLogo"/>
    )
  }

  const copyReferralLink = () => {
    navigator.clipboard.writeText(`${window.location.host}/referral/${campaignId}/${goal.label}?ref=${address}`);
    setOpenTooltip(true);
  }

  const ReferralLinkButton = (_center) => {
    const center = _center === true;
    return ( 
      <ClickAwayListener onClickAway={() => {setOpenTooltip(false)}}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => {setOpenTooltip(false)}}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied to clipboard"
          >
            <Button variant={'contained'} sx={{mx: center  ? 'auto' : 2, display: center ? 'block' : 'flex'}} onClick={copyReferralLink}>Referral Link</Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      height: '100%',
      //minHeight: 'calc(100vh)',
      //overflowX: 'hidden',
      //marginLeft: {md: '250px'},
      backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#282c34',
    }}>
      <Grid
        container
        sx={{
          minWidth: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center"
        }}
        spacing={0}
        alignItems="center"
        justifyItems="center"
      >
        <Grid xs={12} py={1}>
          <Stack 
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{mb: 1}}
          >
            <Box sx={{p:2, px: 4}}>
              <Logo />
            </Box>
            <Box sx={{p:2}}>
              <Web3Button />
            </Box>
            
          </Stack>
        </Grid>
        <Grid xs={12} pt={4}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Countdown endDate={endDate} startDate={startDate}/>
          </Box>
        </Grid>
        <Grid xs={5}>
          <UserGoalProgress goals={goals} userEvents={userEvents}/>
        </Grid>
        <Grid xs={5}>
          <Box p={4}>
            <ProgressBar progress={leaderboardData.reduce((progress, data) => {
              return progress + data.points;
            },0)} goal={communityGoal[0]} />
          </Box>
        </Grid>
        <Grid xs={12}>        
          <CampaignLeaderboard data={leaderboardData}/>
        </Grid>
        
      </Grid>
      <Box sx={{position: 'fixed', bottom: 10, left: 10}}>
        <ReferralLinkButton _center={false}/>
      </Box>
    </Box>
  )
}