// @ts-nocheck

import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState, useEffect } from 'react';
import { makeId } from '../../static/utility';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useContractRead,
  useNetwork,
  useSignMessage
} from 'wagmi';

import LogoDark from '../../assets/images/logo-white.png';
import {Box, Button, Card, CardActions, CardContent, Chip, ClickAwayListener, Container, Divider, Icon, IconButton, Link, Paper, setRef, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {ethers, BigNumber} from 'ethers';
import { getAddress, toHex, zeroAddress } from 'viem'
import { env, useEnvVariable } from '../../static/constants';
import { CampaignOutlined } from "@mui/icons-material";
import { pickColor, truncateString, capitalizeFirstLetter } from '../../static/utility';
import CampaignHeader from '../../components/CampaignHeader';
import { getCampaign, getCampaignResults, getCustomCampaignResults, getLoginStatus, getLeaderboardData, getLoginNoonce, postReferralEvent, hasReferralEvent } from '../../static/api';
import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useAbi } from '../../assets/abis/abi';

import { json2csv } from 'json-2-csv';
import TransactionVolumeByDate from '../../components/Campaign/TransactionVolumeByDate';
import TopReferrers from '../../components/Campaign/TopReferrers';
import TopCustomers from '../../components/Campaign/TopCustomers';
import AddressOutput from '../../components/AddressOutput';
import { useIsLoggedIn } from '../../hooks/useLoginState';

import Countdown from '../../components/Leaderboard/Countdown';
import ProgressBar from '../../components/Leaderboard/GoalGraph';
import UserGoalProgress from '../../components/Leaderboard/UserGoalProgress';
import CampaignLeaderboard from '../../components/Leaderboard/CampaignLeaderboard';
import { Web3Button } from '@web3modal/react';
import LoadingButton from '@mui/lab/LoadingButton';
import SuccessToast from '../../components/SuccessToast';
import ParamInput from '../../components/inputs/ParamInput';
import { SiweMessage } from 'siwe';

// NEXT STEPS - if goal has contract details, load useWriteContract and submit that event. x
// - if goal does not have contract details, use nonce to sign message with payload x
// Send success API request x
// After success, query for existing referral for the signed in address, hide join button otherwise. 
// 

const domain = window.location.host;
const origin = window.location.origin;
const functionRegex = /(\w+)\(([^)]*)\)/;
const networkNames = {
  1: 'Ethereum',
  137: 'Polygon Mainnet',
  80001: 'Polygon Mumbai',
  42161: 'Arbitrum One',
}

export default function Referral({store, setTheme}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {address} = useAccount();
  const theme = useTheme();
  const {chain} = useNetwork();
  const { campaignId, goalName } = useParams();
  const [mongoId, setMongoId] = useState('');
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [factoryAddress, billingAddress] = useEnvVariable();
  const { smartCampaignBilling } = useAbi();
  const { signMessageAsync } = useSignMessage();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasReferral, setHasReferral] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [_campaignAddress, setCampaignAddress] = useState('');
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [goals, setGoals] = useState([]);
  const [goal, setGoal] = useState({
    functionSignature : '',
    functionContractAddress : '',
    functionChainId: 0,
    functionDefaultValues: [''],
    label: '',
    abi: '',
    ctaButton: '',
    bannerText: '',
    _id: ''
  });
  const [customTheme, setCustomTheme] = useState( {
    primary: '',
    secondary: '',
    tertiary: '',
    background: '',
    base_mode: '',
    fonts: {
      header: '',
      body: ''
    }
  })
  const [customLogo, setCustomLogo] = useState('');
  const [returnUrl, setReturnUrl] = useState('');
  const [abi, setAbi] = useState([]);
  const [functionName, setFunctionName] = useState('');
  const [referrer, setReferrer] = useState('');
  const [functionArgs, setFunctionArgs] = useState([]);
  const [inputArgs, setInputArgs] = useState([]);
  const [signature, setMessageSignature] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
 
  const getCampaignId = (argType) => {
    try {
      if (argType === 'bigNum') {
        return BigInt(campaignId)
      } else if (argType === 'hex') {
        return toHex(BigInt(campaignId));
      } else if (argType === 'num') {
        return campaignId ;
      } else {
        return '';
      }      
    } catch (err) {
      return '';
    }
   
  }

  const getReferralEvent = async () => {
    const _hasReferral = await hasReferralEvent(goal._id, address);
    console.log('referral event retrieved', _hasReferral.data);
    setHasReferral(_hasReferral.data);
  }

  const setParam = (paramValue, i) => {
    setInputArgs(inputArgs => inputArgs.map((item, index) => index === i ? paramValue : item));
  }

  const changeFavicon = (link) => {
    let $favicon = document.querySelector('link[rel="icon"]')
    // If a <link rel="icon"> element already exists,
    // change its href to the given link.
    if ($favicon !== null) {
      $favicon.href = link
    // Otherwise, create a new element and append it to <head>.
    } else {
      $favicon = document.createElement("link")
      $favicon.rel = "icon"
      $favicon.href = link
      document.head.appendChild($favicon)
    }
  }
  
  useEffect(() => {
    if (Object.keys(goal).length > 0) {
      //console.log('valid goal', goal);
      const _referrer = searchParams.get('ref');
      //console.log(_referrer);
      if (_referrer !== null) {
        setReferrer(_referrer);
      }
      
      
    }
  }, [goal])

  useEffect(() => {
    if (goal.label !== '' && address) {
      getReferralEvent();
    }
  },[goal, address])

  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(ethers.BigNumber.from(campaignId).toNumber(), billingAddress);
      console.log('referral campaign',camp);
      if (camp) {
        //setCommand(camp.command as string);
        //setListener(camp.listenerAddress as string);
        setMongoId(camp._id as string);
        setStartDate(camp.startDate as number);
        setEndDate(camp.endDate as number);
        setGoals(camp.goals);
        document.title = camp.name;
        
        if (camp.brandData && camp.brandData.theme) {
          setCustomTheme(camp.brandData.theme);
        }
        if (camp.brandData && camp.brandData.logo) {
          setCustomLogo(camp.brandData.logo)
        }
        if (camp.brandData && camp.brandData.icon) {
          changeFavicon(camp.brandData.icon)
        }
        if (camp.brandData && camp.brandData.returnUrl) {
          setReturnUrl(camp.brandData.returnUrl)
        }

        setIsComponentLoaded(true);
      }
      
    }  
    if (campaignId && billingAddress !== '') {
      runAsync();
    }
  }, [campaignId, billingAddress])

  useEffect(() => {
    if (goals.length > 0) {
      const referralGoal = goals.find((_goal) => {
        return _goal.label === goalName;
      });
      setGoal(referralGoal);
    }
  }, [goals, goalName])

  useEffect(() => {
    if (address && campaignId) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [address, campaignId])

  useEffect(() => {
    if (goal.functionSignature !== '') {
      const match = goal.functionSignature.match(functionRegex);
      //console.log(match);
      if (match) {
        setFunctionName(match[1]);
        const argumentString = match[2];
        const args = argumentString.split(',');
        setFunctionArgs(args);
        const _inputArgs = goal.functionDefaultValues.map((val) => {
          if (val === '<customer>') {
            return address;
          } 
          if (val === '') {
            return null;
          }
          return val;
        })
        if (_inputArgs.length === 0) {
          setInputArgs(Array.from('0'.repeat(args.length)));
        } else {
          setInputArgs(_inputArgs);
        }
        
        const _abi = JSON.parse(goal.abi);
        setAbi(_abi);
      } 
    } else if (goal.label) {

    }
  }, [goal])

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false)
      }, 2000)
    }
  }, [openTooltip])

  useEffect(() => {
    if (customTheme.primary !== '') {
      setTheme(customTheme);
    }
  }, [customTheme])


  // Onchain Referral Procedure
  const { config } = usePrepareContractWrite({
    address: (goal && goal.functionContractAddress ? getAddress(goal.functionContractAddress) : zeroAddress),
    abi: abi,
    functionName: functionName,
    args: inputArgs,
    enabled: true,
    onSuccess(data) {
      console.log('Prepare Success');
      console.log(config);
    },
    onError(prepError) {
      console.log('Prepare Error', prepError);
      //console.log(billingAddress, allArgs);
      setSubmissionLoading(false);
      setErrorMessage(prepError.message)
    }
  }
  );
  const { write, status, error, data } = useContractWrite(config);
  const { isLoading, isSuccess, data : transactionResult } = useWaitForTransaction({
    hash: data?.hash,
  })

  useEffect(() => {
    if (isSuccess) {
      console.log('job complete');
      setSuccessMessage('Transaction Successful');
      callAPI();
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000)
    }

    if (!isSuccess && error) {
      console.log('error running');
      setErrorMessage(error.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 2000)
    }
  }, [isSuccess, error])


  // Offchain Referral Procedure
  async function signMessage() {
    try {
      setSubmissionLoading(true);

      const _signature = await signMessageAsync({
        address,
        // Hex data representation of message.
        message: `Referrer: ${referrer}`,
      })
      setMessageSignature(_signature);
      callAPI();

      setSubmissionLoading(false);

      setSuccessMessage('Sign Message Successful');
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000)
    } catch (err) {
      console.log(err);
      setSubmissionLoading(false);
      setErrorMessage(err.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 2000)
      
    }
  }

  // Call API
  const callAPI = async () => {
    const postData = {
      transactionHash: data ? data?.hash : '',
      customer: address,
      affiliate : referrer ? referrer : '',
      goal : goal.label,
      data : {
        arguments: inputArgs,
        signedMessage: signature
      }
    };
    const result = await postReferralEvent(postData, billingAddress, getCampaignId('bigNum').toString());
    console.log(result);
  }

  const Logo = () => {
    return (
      <>
        {customLogo !== '' && 
          <img src={customLogo} className="side-image" style={{maxWidth: '200px'}} alt="menuLogo"/>
        }
        {customLogo === '' && 
          <img src={LogoDark} className="side-image" style={{maxWidth: '200px'}} alt="menuLogo"/>
        }        
      </>
    )
  }

  const copyReferralLink = () => {
    navigator.clipboard.writeText(`${window.location.host}/referral/${campaignId}/${goalName}?ref=${address}`);
    setOpenTooltip(true);
  }

  const ReferralLinkButton = (_center) => {
    const center = _center === true;
    return ( 
      <ClickAwayListener onClickAway={() => {setOpenTooltip(false)}}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => {setOpenTooltip(false)}}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied to clipboard"
          >
            <Button variant={'contained'} sx={{mx: center  ? 'auto' : 2, display: center ? 'block' : 'flex'}} onClick={copyReferralLink}>Referral Link</Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }

  const ReferralButton = () => {
    return (
      <LoadingButton
        component={Button}
        loading={isLoading}
        disabled={!write}
        variant="contained"
        onClick={write}
        sx={{mx: 'auto', display: 'block'}}
        color={'secondary'}
      >
        {goal.ctaButton || "Join!"}
      </LoadingButton>
    )
  }

  const ReferralButtonOffline = () => {
    return (
      <LoadingButton
        component={Button}
        loading={submissionLoading}
        variant="contained"
        onClick={signMessage}
        sx={{mx: 'auto', display: 'block'}}
      >
        Join!
      </LoadingButton>
    )
  }

  const NotConnected = () => {
    return (
      <Box sx={{
      }}>
        <Typography variant='h6' color={customTheme.background ? theme.palette.getContrastText(customTheme.background) : 'text.primary'} textAlign={'center'}>Please connect your wallet.</Typography>
      </Box>
    )
  }

  const WrongNetwork = () => {
    return (
      <Box sx={{
      }}>
        <Typography variant='h6' color={customTheme.background ? theme.palette.getContrastText(customTheme.background) : 'text.primary'} textAlign={'center'}>Please connect the appropriate network: {networkNames[goal.functionChainId]}.</Typography>
      </Box>
    )
  }

  const AlreadyCompleted = () => {
    return (
      <Box sx={{
      }}>
        <Typography variant='h6' color={customTheme.background ? theme.palette.getContrastText(customTheme.background) : 'text.primary'} textAlign={'center'}>Thanks for participating!!</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      height: '100%',
      minHeight: 'calc(100vh)',
      //overflowX: 'hidden',
      //marginLeft: {md: '250px'},
      backgroundColor: customTheme.background === '' ? '#282c34' : customTheme.background,
    }}>
      { campaignId && isComponentLoaded && 
        <>
          <Grid
            container
            sx={{
              minWidth: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
            spacing={0}
            justifyItems="center"
          >
            <Grid xs={12} py={1}>
              <Stack 
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{mb: 1}}
              >
                <Box sx={{p:2, px: 4}}>
                  <a href={returnUrl} style={{textDecoration: 'none'}}>
                    <Logo />
                  </a>
                </Box>
                <Box sx={{p:2}}>
                  {!address && 
                    <Web3Button />
                  }
                  {address && 
                    <ReferralLinkButton _center={false}/>
                  }
                </Box>
              </Stack>
            </Grid>
            {!address && 
              <>
              <Grid xs={12} py={4}>
                  <NotConnected />
              </Grid>
            </>
            }
            {goal && goal.functionSignature !== '' && address &&  !hasReferral && chain.id === goal.functionChainId && 
              <>
                <Grid xs={12} py={4}>
                  <Stack direction={'column'} alignItems={'center'} justifyContent={'space-evenly'} spacing={4}>
                    <Typography variant={'h3'} color={customTheme.background ? theme.palette.getContrastText(customTheme.background) : 'text.primary'}>
                      {goal.bannerText || 'Join!'}
                    </Typography>
                    <Box>
                      {
                        functionArgs.map((arg, i) => {
                          if (inputArgs[i] === null || inputArgs[i] === '0'){
                            return <ParamInput param={arg} setParam={setParam} index={i} key={i+1}></ParamInput>
                          }
                          return <></>
                        })
                      }
                    </Box>

                    <ReferralButton />
                  </Stack>
                </Grid>
              </>
            }

            {goal && goal.functionSignature !== '' && address &&  !hasReferral && chain.id !== goal.functionChainId && 
              <WrongNetwork />
            }

            {goal && goal.functionSignature === '' && address && !hasReferral && 
              <>
                <Grid xs={12} py={4}>
                    <ReferralButtonOffline />
                </Grid>
              </>
            }

            {address && hasReferral &&
              <Grid xs={12} py={4}>
                <AlreadyCompleted />
              </Grid>          
            }
            
          </Grid>
          { address && false && 
            <Box sx={{position: 'fixed', bottom: 10, left: 10}}>
              <ReferralLinkButton _center={false}/>
            </Box>
          }
        </>
      }
      

      
      <SuccessToast successMessage={successMessage}></SuccessToast>
    </Box>
  )
}