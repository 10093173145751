import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Custom Components
import ErrorToast from '../ErrorToast';
import { Paper } from '@mui/material';
import { ethers } from 'ethers';

function DateInput({param, currentValue, setParam}) {
  const [errorMessage, setErrorMessage] = useState('');

  const [paramInput, setParamInput] = useState('');
  const handleParamInput = (event) => {
    setParamInput(event.target.value);
  };

  const [value, setValue] = useState<Dayjs | null>(null);
  
  /*useEffect(() => {
   if (paramInput !== '') {
    try {
      setErrorMessage('');
      const parsedParam = moment(paramInput);
      setParam(BigInt(parsedParam.unix()));
    } catch (err) {
      setErrorMessage('Validation Error')
    }
   }
  }, [paramInput]);*/

  useEffect(() => {
    if (value && value.unix() !== parseInt(currentValue)) {
      console.log(value.unix(), parseInt(currentValue))
     try {
       setErrorMessage('');
       setParam(BigInt(value.unix()));
     } catch (err) {
       setErrorMessage('Validation Error')
     }
    }
   }, [value]);

  useEffect(() => {
    if (currentValue !== '' && value === null) {
      //console.log(value.unix(), parseInt(currentValue))
      //console.log(currentValue);
      //const parsedParam = moment.unix(parseInt(currentValue));
      //const parseVal = parsedParam.format('MM/DD/YYYY');
      //console.log(parsedParam, parseVal);
      //setParamInput(parseVal);
      
      setValue(dayjs.unix(parseInt(currentValue)));
    }
  }, [currentValue])
  return (
    <>
    { /*
      <TextField
        placeholder='MM/DD/YYYY'
        helperText={param}
        error={errorMessage.length > 0}
        variant="standard"
        fullWidth
        defaultValue={paramInput}
        onChange={handleParamInput}
        sx={{display: 'block', mx: "auto"}}
      />       
    */}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          slotProps={{
            textField: {
              helperText: param,
            },
          }}
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
      
    </>
  );
}

export default DateInput;


