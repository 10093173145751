import { useTheme } from '@mui/material';
import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryTooltip } from 'victory';

const ProgressBar = ({ progress, goal }) => {
  const theme = useTheme();
  const data = [{ x: 'Community Progress', y: progress }];
  const strokeDasharray = "10, 5";
  const chartTheme = {
    axis: {
      style: {
        axis: {
          fill: theme.palette.text.primary,
          stroke: theme.palette.grey[500],
        },
        axisLabel: {
          fill: theme.palette.text.primary,
          stroke: theme.palette.text.primary,
          padding: 8
        },
        ticks: {
          fill: theme.palette.text.primary,
          stroke: theme.palette.text.primary,
        },
        tickLabels: {
          fill: theme.palette.text.primary,
          padding: 12
        }, 
        grid: {
          stroke: theme.palette.grey[500],
          strokeDasharray
        }
      },
    },
  };
  
  return (
    <div>
      <VictoryChart
        domain={{ y: [0, goal] }}
        height={300}
        padding={{ top: 20, bottom: 40, left: 50, right: 50 }}
        theme={ chartTheme }
      >
        <VictoryAxis tickValues={[]} />
        <VictoryAxis dependentAxis />
        <VictoryBar
          data={data}
          style={{
            data: { fill: '#8884d8', width: 300 },
            labels: { fill: '#8884d8' },
            parent: { border: '1px solid white', borderRadius: '4px' },
          }}
          labelComponent={<VictoryTooltip />}
        />
      </VictoryChart>
    </div>
  );
};

export default ProgressBar;
