// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Box, Button, Card, Container, Divider, Icon, IconButton, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, alpha, makeStyles, useTheme } from '@mui/material/styles';
import { Link, useParams } from "react-router-dom";
import {ethers, BigNumber} from 'ethers';
import {
  useAccount,
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction
} from 'wagmi';
import { getAddress, zeroAddress } from 'viem'
import { env, useEnvVariable } from '../static/constants';
import { useAbi } from '../assets/abis/abi';
import { asExecuteArg } from "../static/utility";
import { CampaignOutlined } from "@mui/icons-material";
import { pickColor } from '../static/utility';
import ParamInput from '../components/inputs/ParamInput';
import LoadingButton from "@mui/lab/LoadingButton";
import { getCampaign } from "../static/api";
import BigNumberInput from "../components/BigNumberInput";

const functionRegex = /(\w+)\(([^)]*)\)/;

const Widget = ({ 
  //title, 
  //campaignId,
  //colorScheme,
  store
}) => {
  const theme = useTheme();
  const [error, setError] = useState('');  
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [type, setType] = useState('');
  const [functionName, setFunctionName] = useState('');
  const [referrer, setReferrerValue] = useState('');
  const [functionArgs, setFunctionArgs] = useState([]);
  const [inputArgs, setInputArgs] = useState([]);
  const [computedArgs, setComputedArgs] = useState('');
  const [msgValue, setMsgValue] = useState(BigInt(0));
  const [tokenName, setTokenName] = useState('');
  const [destination, setDestination] = useState('');
  const [command, setCommand] = useState('');
  //const [isConnected, setisConnected] = useState('');
  const { campaignId } = useParams();
  const { address, isConnected } = useAccount();
  const {smartReferralFactory: smartReferralFactoryJson, smartReferral : smartReferralJson} = useAbi();
  const [factoryAddress] = useEnvVariable();
  const [referrerAddress, setReferrerAddress] = useState('');
  const [_campaignAddress, setCampaignAddress] = useState('');
  
  useEffect(() => {
    if (campaignId !== '' && campaignId !== null) {
      campaignAddressFetch();
    }
  }, [campaignId])

  const getCampaignId = (argType) => {
    //BigInt(_campaignId)
    try {
      if (argType === 'bigNum') {
        return BigInt(campaignId)
      } else if (argType === 'hex') {
        return BigInt(campaignId).toHexString();
      } else {
        return '';
      }      
    } catch (err) {
      return '';
    }
   
  }
  const { data: campaignAddress, refetch: campaignAddressFetch } = useContractRead({
    address: factoryAddress ? getAddress(factoryAddress) : zeroAddress,
    abi: smartReferralFactoryJson?.abi,
    functionName: 'getReferralContract',
    args: [BigInt(campaignId).toString()],
    enabled: false
  })

  const { config, refetch: contractCallFetch} = usePrepareContractWrite({
    address: _campaignAddress ? getAddress(_campaignAddress) : zeroAddress,
    abi: smartReferralJson?.abi,
    functionName: 'callTransaction',
    enabled: computedArgs.length > 0 && referrerAddress.length > 0 && _campaignAddress.length > 0,
    args: [referrerAddress, computedArgs],
    overrides: {
      value: msgValue,
    },
    onSuccess(data) {
      console.log('Prepare Success');
    },
    onError(prepError) {
      console.log('Prepare Error', prepError);
      
      setSubmissionLoading(false);
      setError(prepError.message);
    }    
  }
  );
  const { write, status, error : contractError, data } = useContractWrite(config);
  const { isLoading, isSuccess: isContractCallSuccessful } = useWaitForTransaction({
    hash: data?.hash,
  })

  const setParam = (paramValue, i) => {
    setInputArgs(inputArgs => inputArgs.map((item, index) => index === i ? paramValue : item));
  }

  const setValue = (value, i) => {
    setMsgValue(value);
  }

  const setReferrer = (value, i) => {
    setReferrerValue(value);
  }

  useEffect(() => {
    //console.log(tokenName);
    if (campaignAddress) {
      setCampaignAddress(campaignAddress.toString());
    }
    
  }, [campaignAddress])

  const callContract = async () => {
    write();
  }
  useEffect(() => {
    const runAsync = async () => {
      const camp = await getCampaign(campaignId, factoryAddress);
      console.log(camp.destination);
      if (camp) {
        setCommand(camp.command as string);
        setDestination(camp.destination as string);
        setTokenName(camp.name as string);
      }
      
    }  
    
    if (isConnected && factoryAddress && campaignId) {
      //setError('No connection set');
      runAsync();
    }
    if (!campaignId) {
      setError('No campaignId set');
    }
  }, [isConnected, campaignId, factoryAddress])

  useEffect(() => {
    if (command) {
      //console.log(command);
      const commandString = command as string;
      const match = commandString.match(functionRegex);

      if (match) {
        const argumentString = match[2];
        const args = argumentString.split(',');
        setFunctionArgs(args);
        setInputArgs(Array.from('x'.repeat(args.length)));
        setFunctionName(match[1]);
      } 
      //const _args = (matches[1] as string).split(',');
      //console.log(_args);
    }
  }, [command])

  useEffect(() => {
    if (
      functionName && 
      functionArgs && 
      inputArgs && 
      functionName.length > 0 && 
      functionArgs.length > 0 && 
      inputArgs.length > 0 && 
      inputArgs.reduce((prev, curr) => {
        if (!prev || curr === 'x') {
          console.log('invalid inputArgs');
          return false;
        }
        return true;
      }, true)
    ) {
      const signature = `function ${functionName}(${functionArgs.join(',')})`
      const ABI = [signature];
      const _interface = new ethers.utils.Interface(ABI);
      try {
        const output = _interface.encodeFunctionData(functionName, inputArgs);
        setComputedArgs(output);
      } catch (err) {
        console.log('error computing arguments');
      }
      
    }    
  }, [functionName, functionArgs, inputArgs])

  useEffect(() => {
    if (referrer.length > 0) {
      console.log(referrer);
      try {
        setReferrerAddress(getAddress(referrer))
      } catch (err) {
      }
      
    }
  }, [referrer]);
  

  useEffect(() => {
    if (isContractCallSuccessful) {
      console.log('contract successful: '+ data?.hash);
    }
    if (!isLoading) {
      setSubmissionLoading(false);
    }
  }, [isLoading, isContractCallSuccessful, data?.hash])

  return (
    <Grid
      container
      sx={{
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
      spacing={0}
      alignItems="center"
      justifyItems="center"
    >
      <Paper elevation={3} sx={{p: 3, m: 2, border: theme.palette.mode === 'light' ? '2px solid #ddd' : '', minWidth: '72vw'}}>
        <Box alignItems={'start'}>
          <Typography color={'text.primary'} variant={'h5'} sx={{fontWeight: 'bold', mb: 2}}>{tokenName as string}</Typography>
          <Divider variant={'fullWidth'} sx={{borderColor: pickColor(), borderWidth: 2, mb: 2}}></Divider>
          <TextField
            id="standard-read-only-input"
            label="Destination Address"
            fullWidth
            defaultValue={destination as string}
            value={destination as string}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
          <code style={{display: 'block', padding: 15, border: '1px solid #eee', backgroundColor: theme.palette.mode === 'light' ? '#eee' : '',  borderRadius: '1rem', marginTop: 10, marginBottom: 10}}>
            {command as string}
          </code>
          {
            functionArgs.map((arg, i) => {
              return <ParamInput param={arg} setParam={setParam} index={i} key={i+1}></ParamInput>
            })
          }
          <BigNumberInput fullwidth={true} label={'msg.value'} callback={setValue} existingValue={false} />
          {
            // <ParamInput param={'msg.value'} setParam={setValue} index={0}></ParamInput>
          }
          <ParamInput param={'referrer'} setParam={setReferrer} index={0}></ParamInput>
        </Box>
        
        <Box alignItems={'end'}>
          <LoadingButton
            component="span"
            color={'secondary'}
            loading={submissionLoading}
            disabled={!write}
            variant="contained"
            onClick={callContract}
            sx={{mt:2, color: theme.palette.mode === 'dark' ? 'white !important' : ''}}
          >
            Submit
          </LoadingButton>
        </Box>
        
      </Paper>
      {
        /*<Widget 
          title="test" 
          campaignId='0x00' 
          colorScheme='purp' 
          prepareSendTransaction={usePrepareContractWrite}
          sendTransaction={useContractWrite}
        ></Widget>*/
      }
    </Grid>
  );
};

export default Widget;