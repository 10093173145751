import React, { useEffect, useState } from 'react';
import { Box, Checkbox, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { match } from 'assert';


const UserGoalProgress = ({ goals, userEvents }) => {
  //const classes = useStyles();
  const theme = useTheme();
  const [parsedGoals, setParsedGoals] = useState([]);
  useEffect(() => { 
    if (goals) { 
      const goalVals = goals.map((goal) => {
        const matchingEvents = userEvents.find((event) => {
          return event.parentId === goal._id;
        });
        return {
          ...goal,
          events: matchingEvents || null,
          hasEvent: (matchingEvents) ? true : false
        }
      });
      //console.log(goalVals);
      setParsedGoals(goalVals);
    }
  }, [goals, userEvents])
  return (
    <Box sx={{maxWidth: 360, backgroundColor: theme.palette.background.paper, p:4}}>
      <Typography color={'text.primary'} variant={'h5'}>Your Progress</Typography>
      <List component="nav">
        {parsedGoals.map((goal, index) => (
          <ListItem key={index}>
            {
              (goal.events && goal.events.length > 1) ?
              <Typography color={'text.primary'} >{goal.events.length}</Typography> : 
              <Checkbox checked={goal.hasEvent} readOnly={true}/>
            }
            
            <ListItemText primary={goal.label} primaryTypographyProps={{
              color: 'text.primary',
              fontWeight: 'medium',
            }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UserGoalProgress;
