import { useEffect, useState } from "react";
import { useNetwork } from 'wagmi';
import { avalanche, bsc, mainnet, polygon, optimism, arbitrum, localhost, hardhat, polygonMumbai } from 'wagmi/chains';
import SmartCampaignBilling_dev from './SmartCampaigns.dev.json';
import SmartReferralAbi_current from './SmartReferral.json';
import SmartReferralFactory_current from './SmartReferralFactory.json';
import SmartReferralAbi_mumbai from './SmartReferral.mumbai.json';
import SmartReferralFactory_mumbai from './SmartReferralFactory.mumbai.json';
import SmartReferralAbi_dev from './SmartReferral.dev.json';
import SmartReferralFactory_dev from './SmartReferralFactory.dev.json';
import ERC20Mock_current from './ERC20Mock.json';



const referralMap = {
  'current': SmartReferralAbi_current,
  'current-mumbai': SmartReferralAbi_current,
  'current-dev': SmartReferralAbi_dev,
  2: SmartReferralAbi_current
};

const factoryMap = {
  'current' : SmartReferralFactory_current,
  'current-mumbai' : SmartReferralFactory_current,
  'current-dev': SmartReferralFactory_dev,
  2: SmartReferralFactory_current
};

const erc20Map = {
  'current': ERC20Mock_current,
  'current-mumbai': ERC20Mock_current,
  'current-dev': ERC20Mock_current,
  2: ERC20Mock_current
};


export const componentMap = {
  'referral' : referralMap,
  'factory' : factoryMap, 
  'erc20' : erc20Map
}


export const useAbi = () => {
  const [smartReferralFactory, setFactory] = useState(null);
  const [smartReferral, setReferral] = useState(null);
  const [smartCampaignBilling, setSmartCampaignBilling] = useState(null);
  const [erc20, setErc20] = useState(null);
  const [error, setError] = useState(null);
  const { chain, chains } = useNetwork();
  

  useEffect(() => {
    //console.log('env variable retrieved', chain);
    if (chain) {
      if (chain.id === polygon.id) {
        setFactory(SmartReferralFactory_current);
        setReferral(SmartReferralAbi_current);
        setErc20(ERC20Mock_current);
      } else if (chain.id === polygonMumbai.id) {
        setFactory(SmartReferralFactory_dev);
        setReferral(SmartReferralAbi_dev);
        setErc20(ERC20Mock_current);
        setSmartCampaignBilling(SmartCampaignBilling_dev);           
      } else if (chain.id === hardhat.id) {
        setFactory(SmartReferralFactory_dev);
        setReferral(SmartReferralAbi_dev);
        setErc20(ERC20Mock_current);           
        setSmartCampaignBilling(SmartCampaignBilling_dev);
      }
    }
  }, [chain]);

  return {smartReferralFactory, smartReferral, erc20, smartCampaignBilling, error};
};