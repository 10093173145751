
import {env} from './static/constants';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { wagmiClient, chains} from './services/wagmiClient';
import { WagmiConfig } from "wagmi";
import reportWebVitals from './reportWebVitals';
import {
  EthereumClient,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";
import Loader from './Loader';
window.Buffer = window.Buffer || require("buffer").Buffer;
// comment lol
// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Loader />
            
  </React.StrictMode>
);
// <DAppProvider config={config_local}></DAppProvider>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
