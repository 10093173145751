import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNetwork } from 'wagmi';
import { avalanche, bsc, mainnet, polygon, optimism, arbitrum, localhost, hardhat, polygonMumbai } from 'wagmi/chains';


export const env = {
  REACT_APP_WALLET_CONNECT_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS: process.env.REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS,
  REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS_MUMBAI: process.env.REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS_MUMBAI,
  REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS_LOCAL: process.env.REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS_LOCAL,
  REACT_APP_API_URL_LOCAL: process.env.REACT_APP_API_URL_LOCAL,
  REACT_APP_IS_NOT_PROD: process.env.REACT_APP_IS_NOT_PROD,
  REACT_APP_ALCHEMY_MUMBAI: process.env.REACT_APP_ALCHEMY_MUMBAI,
  REACT_APP_ALCHEMY_ARBITRUM: process.env.REACT_APP_ALCHEMY_ARBITRUM,
  REACT_APP_SMART_BILLING_ADDRESS_MUMBAI: process.env.REACT_APP_SMART_BILLING_ADDRESS_MUMBAI
}

export const useEnvVariable = () => {
  const [factoryAddress, setFactoryAddress] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [error, setError] = useState<any>(null);
  const { chain, chains } = useNetwork();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log('env variable retrieved', env);
    if (chain) {
      //.log(chain, polygonMumbai);
      if (chain.id === polygon.id) {
        //setFactoryAddress(env.REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS);
        setBillingAddress(env.REACT_APP_SMART_BILLING_ADDRESS_MUMBAI);   
      } else if (chain.id === polygonMumbai.id) {
        //setFactoryAddress(env.REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS_MUMBAI);
        setBillingAddress(env.REACT_APP_SMART_BILLING_ADDRESS_MUMBAI);      
      } else if (chain.id === hardhat.id) {        
        ///setFactoryAddress(env.REACT_APP_SMART_REFERRAL_FACTORY_ADDRESS_LOCAL); 
        setBillingAddress(env.REACT_APP_SMART_BILLING_ADDRESS_MUMBAI);   
      } else {
        setBillingAddress(env.REACT_APP_SMART_BILLING_ADDRESS_MUMBAI);   
      }
    } else {
      setBillingAddress(env.REACT_APP_SMART_BILLING_ADDRESS_MUMBAI);
    }
    
  }, [chain]);

  return [factoryAddress, billingAddress, error] as const;
};