import { AppBar, Box, Toolbar } from "@mui/material"
import NetworkSelect from "./NetworkSelect"
import SocialIcons from "./SocialIcons"


export default function Footer () {
  
  return (
    <AppBar position="relative" color="primary" sx={{ 
      top: 'auto', 
      bottom: 0, 
      backgroundColor: 'transparent !important', 
      backgroundImage: 'none !important',
      boxShadow: 'none !important',
      borderTop: '1px solid white'
      }}>
        <Toolbar sx={{backgroundColor: 'transparent !important'}}>
          {
            //<NetworkSelect/>
          }
          <Box sx={{ flexGrow: 1 }} />
          <SocialIcons 
            discordUrl={''}
            emailUrl={'mailto:ken@smartcampaigns.xyz'}
            twitterUrl={''}
            instagramUrl={''}
            githubUrl={'https://github.com/The18thWarrior'}
            governanceUrl={''}
            websiteUrl={'https://smartcampaigns.xyz'}
            gitbookUrl={'https://smart-campaigns.gitbook.io/api-docs/'}
          />
        </Toolbar>
      </AppBar>
  )
}